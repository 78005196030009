<template>
    <div class="navbar-wrapper" :class="{ 'search-open': isMobileSearchVisible }">
        <!-- Start Navbar Area -->
        <div :class="['navbar-area', { 'is-sticky': isSticky }]">
            <div class="comero-nav">
                <div class="custom-container container--version2">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" href="/">
                            <img :src="websiteLogo()" alt="logo" class="logo" :class="websiteLogoCss()" />
                        </a>

                        <!-- <b-navbar-toggle @click="onClickMobileNavButton"></b-navbar-toggle> -->
                        <MobileNav />

                        <button class="btn-nav-mobile-search" @click="onClickOpenSearch">
                            <img :src="formatAssetUrl('images/search-black.svg')" alt="search icon" />
                        </button>

                        <nuxt-link class="mobile-favorites-link" to="/favorites">
                            <span class="count" v-if="favoritesCount > 0">{{ favoritesCount > 99 ? '!' : favoritesCount }}</span>
                            <img :src="formatAssetUrl('images/heart-black.svg')" alt="heart" />
                        </nuxt-link>


                        <nuxt-link class="mobile-shopping-cart" to="/cart">
                            <span class="count" :class="{ 'is-empty': itemGroups.length === 0 }">{{ itemGroups.length > 99 ? '!' : itemGroups.length }}</span>
                            <img :src="formatAssetUrl('images/header/cart.svg')" alt="cart" />
                        </nuxt-link>

                        <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent" is-nav>
                            <ul class="navbar-nav">
                                <li class="nav-item p-relative" v-for="category in categories" :key="category.id">
                                    <span
                                        class="nav-link main-nav-item"
                                        @mouseover="
                                            handleDropdown(
                                                category.name,
                                                category.lists,
                                                category.slug,
                                                category.product_type
                                            );
                                        "
                                        @mouseleave="shouldHideDropdown"
                                    >
                                        <nuxt-link
                                            :to="category.slug"
                                            @click.native="onClickNavbarLink(category.name)"
                                            :class="{ hovered: category.slug == choosenCategoryLink && isDropdownShow }"
                                        >
                                            {{ category.name }}
                                        </nuxt-link>
                                    </span>
                                    <ul
                                        class="dropdown-menu"
                                        v-if="category.product_type != 8 && category.lists.length > 0"
                                    >
                                        <li class="nav-item" v-for="(item, index) in category.lists" :key="index">
                                            <span
                                                @click="getCategory(item.product_type, item.slug)"
                                                class="nav-sub-link"
                                                >{{ index == 0 ? 'Lens Types' : item.name }}</span
                                            >
                                        </li>
                                    </ul>
                                    <ul
                                        class="dropdown-menu"
                                        v-if="category.product_type == 8 && category.lists.length > 0"
                                    >
                                        <li class="nav-item" v-for="(item, index) in category.lists" :key="index">
                                            <p class="mb-0 inline-links-section">
                                                <template v-if="!item.link">
                                                    {{ item.name }}
                                                </template>
                                                <template v-else>
                                                    <nuxt-link :to="item.link">
                                                        {{ item.name }}
                                                    </nuxt-link>
                                                </template>
                                            </p>
                                            <small class="mb-3 d-block">{{ item.description }}</small>
                                            <p class="ml-3 text-secondary" v-for="list in item.list" :key="list.id">
                                                {{ list }}
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="others-option">
                                <div class="option-item">
                                    <AlgoliaAutoComplete />
                                </div>
                            </div>
                            <div class="account">
                                <ul class="auth">
                                    <li><nuxt-link to="/help">Help</nuxt-link></li>
                                    <li>
                                        <nuxt-link to="/user/login" class="auth-link" v-if="!isLoggedIn"
                                            >Sign In</nuxt-link
                                        >
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>
                        <div class="hidden-mobile navbar-quick-actions-container">
                            <div @mouseover="showFavorites" @mouseleave="shouldHideFavorites">
                                <client-only>
                                    <b-dropdown
                                        ref="favorites"
                                        class="favorites-container"
                                        size="sm"
                                        no-caret
                                        offset="25"
                                        right
                                    >
                                        <template #button-content>
                                            <div @click.stop.prevent="goToFavorites" class="shopping-cart">
                                                <span class="count" v-if="favoritesCount > 0">{{ favoritesCount }}</span>
                                                <img :src="formatAssetUrl('images/heart-black.svg')" alt="heart icon" />
                                            </div>
                                        </template>
                                        <b-dropdown-text class="favorites-dropdown" style="width: 345px;">
                                            <div class="p-1">
                                                <div class="favorites-length pb-3">My Favorites ({{ customerFavoritesAll.length }})</div>
                                                <div v-for="(product, index) in customerFavorites"
                                                    :product="product"
                                                    :key="product.id"
                                                    class="row"
                                                    :class="{'third': index == customerFavorites.length -1}"
                                                    @click="goToProduct(product)"
                                                >
                                                    <div class="col favorite-item d-flex flex-row align-items-center justify-content-between">
                                                        <div class="d-flex flex-row justify-content-start align-items-center">
                                                            <div class="mr-3"><img :src="formatProductImageUrl(product.image.url)" style="width:100px"></div>
                                                            <div class="d-flex flex-column">
                                                                <div class="favorite-name">{{ getDisplayName(product) }} </div>
                                                                <div class="favorite-color" v-if="getFrameColor(product)">{{ getFrameColor(product) }}</div>
                                                                <div class="favorite-price mt-1">${{ formatMoney(product.price, 0) }}</div>
                                                            </div>
                                                        </div>
                                                        <div @click.stop.prevent="confirmRemoveFavorite(product)">
                                                            <font-awesome-icon
                                                                icon="fa-solid fa-xmark"
                                                                aria-label="Remove from favorites"
                                                                size="lg"
                                                                :style="{color: '#91a5b4'}"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" class="favorite-button mt-3" @click="goToFavorites">See My Favorites</button>
                                            </div>
                                        </b-dropdown-text>
                                    </b-dropdown>
                                    </client-only>
                            </div>
                            <nuxt-link to="/cart" class="shopping-cart">
                                <span class="count" :class="{ 'is-empty': itemGroups.length === 0 }">{{ itemGroups.length }}</span>
                                <img :src="formatAssetUrl('images/header/cart.svg')" alt="cart" />
                            </nuxt-link>
                        </div>
                    </nav>
                </div>
                <div class="dropdown-container"
                    v-if="!isHoveringRewards"
                >
                    <div
                        class="navDropdown"
                        v-if="isDropdownShow"
                        @mouseover="isHoveringDropdown = true"
                        @mouseleave="hideDropdown()"
                    >
                        <div class="row" v-if="choosenCategoryType === 1">
                            <div class="col-md-3 col-sm-6 border-right">
                                <p class="p16">Lens Type</p>
                                <p class="text-secondary mb-2" v-for="(text, i) in choosenCategoryLists" :key="i">
                                    <nuxt-link
                                        :to="text.slug ? text.slug : '#'"
                                        class="bold text-secondary"
                                        @click.native="onClickNavbarLink"
                                        >{{ text.name }}</nuxt-link
                                    >
                                </p>
                            </div>
                            <div class="col-md-9 col-sm-6">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="p16">Shop by Brand</p>
                                        <nuxt-link
                                            :to="'/' + item.slug"
                                            class="brand"
                                            v-for="(item, index) in brands"
                                            :key="index"
                                            @click.native="onClickNavbarLink"
                                        >
                                            <div class="d-flex align-items-center justify-content-center h-100">
                                                <img
                                                    :src="formatAssetUrl('images/' + item.image)"
                                                    :alt="item.alt"
                                                    :class="item.slug"
                                                />
                                            </div>
                                        </nuxt-link>
                                        <nuxt-link to="/contact-lenses" class="brand browse-all">
                                            <div class="d-flex align-items-center justify-content-center h-100">
                                                Browse All
                                            </div>
                                        </nuxt-link>
                                    </div>
                                    <div class="col-md-4">
                                        <nuxt-link
                                            to="/autorefill"
                                            class="black-card autorefill-card"
                                            @click.native="onClickNavbarLink"
                                            ><span class="autorefill-title">Subscribe</span
                                            ><span class="autorefill-text"> and save 20% today + 10% always</span>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="choosenCategoryType === 2">
                            <div class="col-md-3 col-sm-6 border-right">
                                <p class="p16">Shop</p>
                                <p class="text-secondary mb-2" v-for="(text, i) in choosenCategoryLists[0]" :key="i">
                                    <nuxt-link
                                        :to="text.slug ? text.slug : '#'"
                                        class="bold text-secondary"
                                        @click.native="onClickNavbarLink"
                                        >{{ text.name }}</nuxt-link
                                    >
                                </p>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <p class="p16">Glasses</p>
                                <p class="text-secondary mb-2" v-for="(text, i) in choosenCategoryLists[1]" :key="i">
                                    <nuxt-link
                                        :to="text.slug ? text.slug : '#'"
                                        class="bold text-secondary"
                                        @click.native="onClickNavbarLink"
                                        >{{ text.name }}
                                        <span v-if="text.isNew" class="badge-red">NEW</span></nuxt-link
                                    >
                                </p>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="row">
                                    <div class="d-flex flex-wrap">
                                        <nuxt-link
                                            to="/glasses-best-sellers"
                                            class="black-card nav-image-card glasses-popular"
                                            @click.native="onClickNavbarLink"
                                            ><span class="title black">BEST SELLERS</span
                                            ><span class="text black">Starting at just $34</span>
                                        </nuxt-link>
                                        <nuxt-link
                                            to="/glasses-new-arrivals"
                                            class="black-card nav-image-card glasses-arrivals"
                                            @click.native="onClickNavbarLink"
                                            ><span class="title">NEW ARRIVALS</span
                                            ><span class="text">Starting at just $69</span>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="choosenCategoryType === 3">
                            <div class="col-md-3 col-sm-6 border-right">
                                <p class="p16">Shop</p>
                                <p
                                    class="bold text-secondary mb-2"
                                    v-for="(text, i) in choosenCategoryLists[0]"
                                    :key="i"
                                >
                                    <nuxt-link
                                        :to="text.slug ? text.slug : '#'"
                                        class="bold text-secondary"
                                        @click.native="onClickNavbarLink"
                                        >{{ text.name }}</nuxt-link
                                    >
                                </p>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <p class="p16">Sunglasses</p>
                                <p class="text-secondary mb-2" v-for="(text, i) in choosenCategoryLists[1]" :key="i">
                                    <nuxt-link
                                        :to="text.slug ? text.slug : '#'"
                                        class="bold text-secondary"
                                        @click.native="onClickNavbarLink"
                                        >{{ text.name }}
                                        <span v-if="text.isNew" class="badge-red">NEW</span>
                                        </nuxt-link
                                    >

                                </p>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="row">
                                    <div class="d-flex flex-wrap">
                                        <nuxt-link
                                            to="/hyannis-sunglasses"
                                            class="black-card nav-image-card sunglasses-hyannis"
                                            @click.native="onClickNavbarLink"
                                            ><span class="title">HYANNIS</span
                                            ><span class="text">JFK-inspired sunglasses</span>
                                        </nuxt-link>
                                        <nuxt-link
                                            to="/ray-ban-sunglasses"
                                            class="black-card nav-image-card rayban-sunglasses"
                                            @click.native="onClickNavbarLink"
                                            ><span class="title">RAY-BAN</span
                                            ><span class="text">Legendary since 1937</span>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="choosenCategoryType === 8">
                            <div class="col-md-3">
                                <div v-for="(text, i) in choosenCategoryLists" :key="i">
                                    <div v-if="i === 0">
                                        <p class="p16 mb-0 inline-links-section text-black">
                                            <template v-if="!text.link"> {{ text.name }} </template>
                                            <template v-else>
                                                <nuxt-link :to="text.link" @click.native="onClickNavbarLink">
                                                    {{ text.name }}
                                                </nuxt-link>
                                            </template>
                                        </p>
                                        <p class="lens-replacement-subtitle mb-3">{{ text.description }}</p>
                                        <p class="text-secondary mb-2" v-for="(item, i) in text.list" :key="i">
                                            <nuxt-link
                                                :to="item.slug ? item.slug : '#'"
                                                class="text-secondary bold"
                                                @click.native="onClickNavbarLink"
                                            >
                                                {{ item.name }}
                                            </nuxt-link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 border-right">
                                <nuxt-link
                                    to="/choose-your-lenses"
                                    class="black-card lens-replacement left"
                                    @click.native="onClickNavbarLink"
                                    ><span class="title">Prescription Lens <br />Replacement</span
                                    ><span class="text">Starting at $64</span>
                                </nuxt-link>
                            </div>
                            <div class="col-md-3">
                                <div v-for="(text, i) in choosenCategoryLists" :key="i">
                                    <div v-if="i === 1">
                                        <p class="p16 mb-0 inline-links-section text-black">
                                            <template v-if="!text.link">
                                                {{ text.name }}
                                            </template>
                                            <template v-else>
                                                <nuxt-link :to="text.link" @click.native="onClickNavbarLink">
                                                    {{ text.name }}
                                                </nuxt-link>
                                            </template>
                                        </p>
                                        <div class="badge-container">
                                            <div class="non-rx-badge">Non-Rx Only</div>
                                        </div>
                                        <p class="lens-replacement-subtitle mb-3">{{ text.description }}</p>
                                        <p class="text-secondary mb-2" v-for="(item, i) in text.list" :key="i">
                                            <nuxt-link
                                                :to="item.slug ? item.slug : '#'"
                                                class="text-secondary bold"
                                                @click.native="onClickNavbarLink"
                                            >
                                                {{ item.name }}
                                            </nuxt-link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <nuxt-link
                                    to="/diy-lens-replacement/choose-your-brand"
                                    class="black-card lens-replacement right"
                                    @click.native="onClickNavbarLink"
                                    ><span class="title">DIY Lens <br />Replacement</span
                                    ><span class="text">Starting at ${{ diyLensesStartingPrice }}</span>
                                </nuxt-link>
                            </div>
                        </div>
                        <div class="row" v-if="choosenCategoryType === 4">
                            <div
                                :class="{ 'col-md-2': i === 0, 'col-md-4': i !== 0, 'border-right': i === 0 }"
                                v-for="(text, i) in choosenCategoryLists"
                                :key="i.name"
                            >
                                <p class="p16">{{ text.name }}</p>
                                <p class="text-secondary mb-2" v-for="(item, i) in text.list" :key="i.name">
                                    <nuxt-link
                                        v-if="!item.link"
                                        :to="item.slug ? item.slug : '#'"
                                        class="text-secondary bold"
                                        @click.native="onClickNavbarLink"
                                    >
                                        {{ item.name }}
                                    </nuxt-link>
                                    <a
                                        v-else
                                        :href="item.link"
                                        class="text-secondary bold"
                                    >
                                        {{ item.name }}
                                    </a>
                                </p>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="d-flex flex-wrap">
                                        <nuxt-link
                                            v-for="item in moreSectionSales" :key="item.id"
                                            :to="item.slug"
                                            :class="item.className"
                                            @click.native="onClickNavbarLink"
                                        >
                                            <span class="title">{{ item.title }}</span>
                                            <span class="subtitle" v-html="item.subtitle"></span>
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- End Navbar Area -->
        <div class="overlay navbar-overlay" v-if="showOverlay"></div>

        <ConfirmModal2
            v-model="viewRemoveModal"
            @confirmed="removeFavorite"
            action="Remove"
        >

            Are you sure you want to delete this item from your favorites?
        </ConfirmModal2>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import SidebarPanel from '@/layouts/SidebarPanel';
import MobileNav from '@/components/MobileNav';
import imageUrlFormatMixin from '@/mixins/image-url-formatter';
import productTypes from '@/constants/product-types';
import moment from 'moment';
import NavbarSearchSkeleton from '@/components/NavbarSearchSkeleton';
import NavbarSearchResultsCountSkeleton from '@/components/NavbarSearchResultsCountSkeleton';
import freezeMobileBackdrop from '@/utils/freeze-mobile-backdrop';
import hideMobileChatWindow from '@/utils/hide-mobile-chat-window';
import AlgoliaAutoComplete from '@/components/third-party/AlgoliaAutoComplete';
import ConfirmModal2 from '@/components/modals/ConfirmModal2';
import notificationsMixin from '@/mixins/notifications';
import formatMoneyMixin from '@/mixins/money-formatter';


export default {
    name: 'AppNavbar',
    mixins: [imageUrlFormatMixin, notificationsMixin, formatMoneyMixin],
    components: {
        SidebarPanel,
        MobileNav,
        NavbarSearchSkeleton,
        NavbarSearchResultsCountSkeleton,
        AlgoliaAutoComplete,
        ConfirmModal2
    },
    beforeDestroy() {
        //window.removeEventListener('scroll', this.onScroll, true);
        //window.removeEventListener('touchstart', this.onMobileMomentumScroll, true);
        //window.removeEventListener('resize', this.onScroll, true);
    },
    data() {
        return {
            mobileScrollInterval: null,
            mobileScrollY: null,
            blurSearchTimeout: null,
            searchTimeout: null,
            searchController: null,
            isSticky: true,
            isMobileScroll: false,
            isHoveringMenuItem: false,
            isHoveringDropdown: false,
            isHoveringDropdownTimeout: null,
            showMobileNav: false,
            isHoveringRewards: false,
            categories: [
                {
                    id: 1,
                    name: 'Contact Lenses',
                    slug: '/contact-lenses',
                    product_type: 1,
                    lists: [
                        {
                            id: 1,
                            name: 'Daily',
                            slug: '/daily-contacts',
                            product_type: 1
                        },
                        {
                            id: 2,
                            name: 'Weekly',
                            slug: '/weekly-contacts',
                            product_type: 1
                        },
                        {
                            id: 3,
                            name: 'Monthly',
                            slug: '/monthly-contacts',
                            product_type: 1
                        },
                        {
                            id: 4,
                            name: 'Toric',
                            slug: '/toric-contact-lenses',
                            product_type: 1
                        },
                        {
                            id: 5,
                            name: 'Multifocal',
                            slug: '/multifocal-contact-lenses',
                            product_type: 1
                        },
                        {
                            id: 6,
                            name: 'Shop All Contacts',
                            slug: '/contact-lenses',
                            product_type: 1
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'Glasses',
                    slug: '/glasses',
                    product_type: 2,
                    lists: [
                        [
                            {
                                id: 1,
                                name: "Women's Glasses",
                                slug: '/womens-designer-eyeglasses',
                                product_type: 1
                            },
                            {
                                id: 2,
                                name: "Men's Glasses",
                                slug: '/mens-prescription-glasses',
                                product_type: 1
                            },
                            {
                                id: 3,
                                name: 'Children\'s Eyewear',
                                slug: '/childrens-eyewear',
                                product_type: 1
                            },
                            {
                                id: 4,
                                name: 'Progressives',
                                slug: '/progressives',
                                product_type: 1
                            },
                            {
                                id: 5,
                                name: 'Readers',
                                slug: '/readers',
                                product_type: 1
                            },
                            {
                                id: 6,
                                name: 'Shop All Glasses',
                                slug: '/glasses',
                                product_type: 1
                            }
                        ],
                        [
                            {
                                id: 1,
                                name: '$34 and Under',
                                slug: '/glasses-under-35'
                            },
                            {
                                id: 2,
                                name: 'Best Sellers',
                                slug: '/glasses-best-sellers',
                                product_type: 1
                            },
                            {
                                id: 3,
                                name: 'Take an Eye Exam',
                                slug: '/vision-test'
                            },
                            {
                                id: 4,
                                name: 'Get Your PD',
                                slug: '/pd-measurement'
                            },
                            {
                                id: 5,
                                name: 'Vision Insurance',
                                slug: '/insurance'
                            },
                            {
                                id: 6,
                                name: 'Ray-Ban',
                                slug: '/ray-ban',
                                isNew: true
                            }
                        ]
                    ]
                },
                {
                    id: 3,
                    name: 'Sunglasses',
                    slug: '/sunglasses',
                    product_type: 3,
                    lists: [
                        [
                            {
                                id: 1,
                                name: "Women's Sunglasses",
                                slug: '/womens-sunglasses',
                                product_type: 1
                            },
                            {
                                id: 2,
                                name: "Men's Sunglasses",
                                slug: '/mens-sunglasses',
                                product_type: 1
                            },
                            {
                                id: 3,
                                name: 'Best Sellers',
                                slug: '/sunglasses-best-sellers'
                            },
                            {
                                id: 4,
                                name: 'New Arrivals',
                                slug: '/sunglasses-new-arrivals'
                            },
                            {
                                id: 4,
                                name: 'Shop All Sunglasses',
                                slug: '/sunglasses'
                            }
                        ],
                        [
                            {
                                id: 1,
                                name: 'Hyannis Sunglasses',
                                slug: '/hyannis-sunglasses'
                            },
                            {
                                id: 2,
                                name: 'Holly Sunglasses',
                                slug: '/holly-sunglasses'
                            },
                            {
                                id: 3,
                                name: 'Brian Mazza Collection',
                                slug: '/brian-mazza-collection'
                            },
                            {
                                id: 4,
                                name: 'Clip-On Glasses',
                                slug: '/clipon-glasses'
                            },
                            {
                                id: 5,
                                name: 'Ray-Ban',
                                slug: '/ray-ban-sunglasses',
                                isNew: true
                            }
                        ]
                    ]
                },
                {
                    id: 4,
                    name: 'Lens Replacement',
                    slug: '/choose-your-lenses',
                    product_type: 8,
                    lists: [
                        {
                            id: 1,
                            name: 'Full Service',
                            description: 'Send us the frames and we handle installation.',
                            link: '/lens-replacement/prescription-lens-replacement',
                            list: [
                                {
                                    id: 1,
                                    name: 'Prescription Lenses',
                                    slug: '/lens-replacement/prescription-lens-replacement'
                                },
                                {
                                    id: 2,
                                    name: 'Prescription Sunglass Lenses',
                                    slug: '/lens-replacement/prescription-sunglasses-lens-replacement'
                                },
                                {
                                    id: 3,
                                    name: 'Non-Prescription Sunglass Lenses',
                                    slug: '/lens-replacement/non-prescription-sunglass-lens-replacement'
                                }
                            ]
                        },
                        {
                            id: 2,
                            name: 'DIY Sunglasses',
                            description: "We'll mail the lenses and you pop 'em right in.",
                            link: '/diy-lens-replacement/choose-your-brand',
                            list: [
                                {
                                    id: 1,
                                    name: 'Ray-Ban Lenses',
                                    slug: '/ray-ban-lens-replacement'
                                },
                                {
                                    id: 2,
                                    name: 'Oakley Lenses',
                                    slug: '/oakley-lens-replacement'
                                },
                                {
                                    id: 3,
                                    name: 'Maui Jim Lenses',
                                    slug: '/mauijim-lens-replacement'
                                },
                                {
                                    id: 4,
                                    name: 'Tom Ford Lenses',
                                    slug: '/tomford-lens-replacement'
                                },
                                {
                                    id: 5,
                                    name: 'Costa Del Mar Lenses',
                                    slug: '/costadelmar-lens-replacement'
                                },
                                {
                                    id: 6,
                                    name: 'All Brands',
                                    slug: '/diy-lens-replacement/choose-your-brand'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 6,
                    name: 'Discover',
                    slug: '/accessories',
                    product_type: 4,
                    lists: [
                        {
                            name: 'Explore',
                            list: [
                                {
                                    id: 1,
                                    name: 'Accessories',
                                    slug: '/accessories'
                                },
                                {
                                    id: 2,
                                    name: 'Online Vision Test',
                                    slug: '/vision-test'
                                },
                                /*
                                {
                                    id: 3,
                                    name: 'Referral Program',
                                    slug: '/refer'
                                },
                                */
                                {
                                    id: 4,
                                    name: 'Gift Cards',
                                    slug: '/giftcards'
                                },
                                {
                                    id: 5,
                                    name: 'Vision Insurance',
                                    slug: '/insurance'
                                },
                                {
                                    id: 6,
                                    name: 'LD Rewards',
                                    slug: '/rewards',
                                    link: '/rewards'
                                }
                            ]
                        },
                        {
                            name: 'Guides',
                            list: [
                                {
                                    name: 'How Lens Replacement Works',
                                    slug: '/how-lens-replacement-works'
                                },
                                {
                                    name: 'How to Identify Your Sunglasses',
                                    slug: '/how-to-identify-sunglasses'
                                },
                                {
                                    name: 'Face Shape Guide',
                                    slug: '/face-shape-guide'
                                },
                                {
                                    name: 'How to Install Your Lenses',
                                    slug: '/how-to-install-sunglass-lenses'
                                },
                                {
                                    name: 'How to Measure Your Pupillary Distance',
                                    slug: '/pd-measurement'
                                }
                            ]
                        }
                    ]
                }
            ],
            isSearch: false,
            searchResultCount: 18,
            isLoadingSearchResults: false,
            searchTerm: '',
            lastSearchTerm: '',
            selectedSearchIndex: -1,
            searchResult: [],
            searchableProductTypes: [
                productTypes.CONTACT_LENSES.id,
                productTypes.SUNGLASSES.id,
                productTypes.GLASSES.id,
                productTypes.ACCESSORIES.id,
                productTypes.VISION_TEST.id,
                productTypes.LENS_REPLACEMENT.id,
                productTypes.DIY_LENSES.id,
                productTypes.GIFT_CARD.id
            ],
            brands: [
                {
                    image: 'brand-logos/acuvue-black.png',
                    alt: 'Acuvue',
                    slug: 'acuvue-contacts'
                },
                {
                    image: 'brand-logos/air-optix-black.png',
                    alt: 'Alt Optix',
                    slug: 'air-optix'
                },
                {
                    image: 'brand-logos/avaira-black.svg',
                    alt: 'Avaira',
                    slug: 'avaira'
                },
                {
                    image: 'brand-logos/bausch-lomb-black.png',
                    alt: 'Bausch & Lomb',
                    slug: 'bausch-and-lomb'
                },
                {
                    image: 'brand-logos/biofinity-black.png',
                    alt: 'Biofinity',
                    slug: 'biofinity'
                },
                {
                    image: 'brand-logos/biomedics-black.svg',
                    alt: 'Biomedics',
                    slug: 'biomedics'
                },
                {
                    image: 'brand-logos/clariti-black.png',
                    alt: 'Clariti',
                    slug: 'clariti'
                },
                {
                    image: 'brand-logos/dailies-black.png',
                    alt: 'Dailies',
                    slug: 'dailies'
                },
                {
                    image: 'brand-logos/precision1-black.svg',
                    alt: 'Precision1',
                    slug: 'precision-1'
                },
                {
                    image: 'brand-logos/proclear-black.svg',
                    alt: 'Proclear',
                    slug: 'proclear'
                },
                {
                    image: 'brand-logos/purevision-black.svg',
                    alt: 'Purevision',
                    slug: 'purevision'
                },
                {
                    image: 'brand-logos/soflens-black.svg',
                    alt: 'Soflens',
                    slug: 'soflens'
                },
                {
                    image: 'brand-logos/ultra-black.svg',
                    alt: 'Ultra',
                    slug: 'ultra'
                }
            ],
            glassesSales: [
                {
                    name: 'Most Popular',
                    slug: '/popular-glasses',
                    className: 'nav-popular-glasses-bg'
                },
                {
                    name: 'New Arrivals',
                    slug: '/glasses-new-arrivals',
                    className: 'nav-glasses-new-arrivals-bg'
                },
                {
                    name: 'Sale',
                    slug: '/glasses-sales',
                    className: 'nav-glasses-sales-bg'
                }
            ],
            sunglassesSales: [
                {
                    name: 'Hyannis',
                    slug: '/hyannis-sunglasses',
                    className: 'nav-hyannis-sunglasses-bg'
                },
                {
                    name: 'New Arrivals',
                    slug: '/sunglasses-new-arrivals',
                    className: 'nav-sunglasses-new-arrivals-bg'
                },
                {
                    name: 'Sale',
                    slug: '/sunglasses-sales',
                    className: 'nav-sunglasses-sales-bg'
                }
            ],
            moreSectionSales: [
                {
                    title: 'Online Vision Test',
                    subtitle: 'Renew your Rx<br>for just $15',
                    slug: '/vision-test',
                    className: 'vision-test-bg discover-sales-item'
                },
                {
                    title: 'LensDirect App',
                    subtitle: 'Scan code to download',
                    slug: '/app',
                    className: 'app-download-bg discover-sales-item'
                }
            ],
            sales: ['hyannis', 'new arrivals', 'sale'],
            isLoggedIn: false,
            isDropdownShow: false,
            choosenCategoryLists: [],
            choosenCategoryType: '',
            choosenCategoryLink: '',
            clickedNavbarLinkTimeout: null,
            clickedNavbarLink: false,

            isHovering: {
                favorites: {
                    refKey: 'favorites',
                    dropdown: false,
                    menuItem: false,
                    dropdownTimeout: false
                },
            },
            selectedFavoriteProduct: null,
            viewRemoveModal: false
        };
    },
    computed: {
        diyLensesStartingPrice() {
            return this.$store.state.diyLenses.diyLensStartingPrice;
        },
        customerFavoritesAll() {
            return this.$store.state.favorites.customerFavorites ? this.$store.state.favorites.customerFavorites : [];
        },
        customerFavorites(){
            return this.customerFavoritesAll.slice(0,3);
        },
        favoritesCount() {
            return this.$store.state.favorites.customerFavorites
                ? this.$store.state.favorites.customerFavorites.length
                : 0;
        },
        isMobileSearchVisible() {
            return this.$store.state.search.isVisible;
        },
        expDate() {
            let expDate = moment().add('1 day');
            return expDate.format('MM/DD/YYYY');
        },
        showAllPath() {
            let counts = {};
            for (let x = 0; x < this.searchResult.length; x++) {
                let product = this.searchResult[x];
                let productType = product.productType;
                if (this.searchableProductTypes.indexOf(productType) === -1) {
                    continue;
                }

                if (typeof counts[productType] === 'undefined') {
                    counts[productType] = 0;
                }

                counts[productType]++;
            }

            let maxProductType = null;
            let maxCount = null;
            for (let productType in counts) {
                if (maxProductType === null) {
                    maxProductType = parseInt(productType);
                    maxCount = counts[productType];
                    continue;
                }

                if (maxCount < counts[productType]) {
                    maxProductType = parseInt(productType);
                    maxCount = counts[productType];
                }
            }

            switch (maxProductType) {
                case productTypes.SUNGLASSES.id:
                    return '/sunglasses';
                case productTypes.GLASSES.id:
                    return '/glasses';
                case productTypes.ACCESSORIES.id:
                    return '/accessories';
                case productTypes.VISION_TEST.id:
                    return '/vision-test';
                case productTypes.LENS_REPLACEMENT.id:
                    return '/choose-your-lenses';
                case productTypes.DIY_LENSES.id:
                    return '/diy-lens-replacement';
                case productTypes.GIFT_CARD.id:
                    return '/giftcards';
            }

            return '/contact-lenses';
        },
        cart() {
            return this.$store.getters.cart;
        },
        itemGroups() {
            return this.$store.getters.itemGroups;
        },
        showOverlay() {
            if (!this.isDropdownShow) {
                return false;
            }

            let elements = document.getElementsByClassName('overlay');
            if (!elements || elements.length === 0) {
                return true;
            }

            return true;
        }
    },
    methods: {
        goToFavorites(){
            this.$router.push('/favorites');
        },
        goToProduct(product){
            this.$router.push('/' + product.slug);
        },
        getFrameColor(product) {
            return product?.attributes?.frameColor;
        },
        getDisplayName(product) {
            return product?.displayName ? product.displayName : product.name;
        },
        confirmRemoveFavorite(product){
            this.selectedFavoriteProduct = product;
            this.viewRemoveModal = true;
        },
        async removeFavorite() {
            try {
                let response = await this.$axios.$delete('/customer/favorite/product/' + this.selectedFavoriteProduct.id);
                await this.$store.dispatch('favorites/setCustomerFavorites', response);
            } catch (e) {
                // Prevent issues saving favorite
            }
            this.successMessage(`Removed ${this.selectedFavoriteProduct.displayName} from favorites!`);
        },
        showFavorites(){
            if(this.customerFavorites.length < 1){
                return;
            }
            this.isHovering.favorites.menuItem = true;
            this.$refs.favorites.visible = true;
        },
        shouldHideFavorites(){
            if(this.customerFavorites.length < 1){
                this.hidefavorites();
            }

            if (this.isHovering.favorites.dropdownTimeout) {
                window.clearTimeout(this.isHovering.favorites.dropdownTimeout);
            }

            this.isHovering.favorites.menuItem = false;
            this.isHovering.favorites.dropdownTimeout = window.setTimeout(() => {
                if (!this.isHovering.favorites.dropdown && !this.isHovering.favorites.menuItem) {
                    this.hidefavorites();
                }
            }, 200);
        },
        hidefavorites(){
            let refKey = this.isHovering.favorites.refKey;
            if (typeof this.$refs[refKey] !== 'undefined') {
                this.$refs[refKey].visible = false;
            }

            this.isHovering.favorites.dropdown = false;
            this.isHovering.favorites.menuItem = false;
        },
        formatSearchResults(searchResults) {
            let formattedResults = [];
            for (let searchResult of searchResults) {
                if (this.searchableProductTypes.indexOf(searchResult.productType) == -1) {
                    continue;
                }

                formattedResults.push(searchResult);
            }

            return formattedResults;
        },
        onClickOpenSearch() {
            this.$store.dispatch('setMobileMenuOpen', true);
            this.$store.dispatch('search/setIsVisibleStatus', true);
            this.$store.dispatch('search/openedFromNavbar');
            freezeMobileBackdrop(true);
            hideMobileChatWindow(true);
            this.handleAlgoliaFocus();
        },
        handleAlgoliaFocus() {
            // Hack to workaround ios issue with not being able to focus the input normally
            // https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click

            const fakeInput = document.createElement('input');
            fakeInput.setAttribute('type', 'text');
            fakeInput.style.position = 'absolute';
            fakeInput.style.opacity = .15;
            fakeInput.style.height = 0;
            fakeInput.style.fontSize = '16px';
            document.body.prepend(fakeInput);
            fakeInput.focus();

            window.setTimeout(() => {
                try {
                    const algoliaInput = document.querySelector('#algoliaMobileAutoComplete input.aa-Input');
                    if (algoliaInput) {
                        algoliaInput.focus();
                    }
                } catch (e) {
                    // Prevent issues focusing
                    console.error(e);
                }

                fakeInput.remove();
            }, 400);
        },
        onHoverSearchResult() {
            this.selectedSearchIndex = -1;
        },
        onMobileMomentumScroll(e) {
            if (typeof window === 'undefined') {
                return;
            }

            if (window.innerWidth > 1060) {
                return;
            }

            if (this.mobileScrollInterval) {
                window.clearInterval(this.mobileScrollInterval);
            }

            this.mobileScrollInterval = window.setInterval(() => {
                if (this.mobileScrollY != window.top.scrollY) {
                    this.onScroll(e);
                    this.mobileScrollY = window.top.scrollY;
                }
            }, 200);
        },
        onScroll(e) {
            const bar = document.getElementsByClassName('navbar-wrapper');

            // sticky scroll navbar on mobile
            if (window.innerWidth > 1060) {
                // Reset top just in case going from mobile to desktop
                bar[0].style.top = '';
                return;
            }

            if (window.top.scrollY > 40) {
                bar[0].style.top = '0px';
                document.body.classList.add('hidden-top-panel');
            } else {
                bar[0].style.top = 40 - window.top.scrollY + `px`;
                document.body.classList.remove('hidden-top-panel');
            }
        },
        ...mapActions(['getCategoryData', 'getProductData']),

        async toggle() {
            await this.$store.dispatch('sidebar/toggleNav');
        },
        onClickNavbarLink(dropdownName = '') {
            this.hideDropdown();

            if (this.clickedNavbarLinkTimeout !== null) {
                window.clearTimeout(this.clickedNavbarLinkTimeout);
            }

            this.clickedNavbarLink = dropdownName;
            this.clickedNavbarLinkTimeout = window.setTimeout(() => {
                this.clickedNavbarLink = false;
            }, 1500);
        },
        onClickSearchResultArea() {
            if (this.blurSearchTimeout) {
                window.clearTimeout(this.blurSearchTimeout);
            }

            this.onBlurSearch();
        },
        onBlurSearch() {
            this.blurSearchTimeout = window.setTimeout(() => {
                this.isSearch = false;
            }, 120);
        },
        onFocusSearch() {
            if (this.searchResult.length === 0) {
                return;
            }

            this.isSearch = true;
        },
        onSearchSubmit(e) {
            // Prevent submitting search form
        },
        onSelectIndex() {
            if (this.selectedSearchIndex === -1) {
                return;
            }

            let selectedProduct =
                typeof this.searchResult[this.selectedSearchIndex] !== 'undefined'
                    ? this.searchResult[this.selectedSearchIndex]
                    : null;

            if (selectedProduct !== null) {
                this.$router.push({ path: '/' + selectedProduct.slug });
                this.isSearch = false;
                return;
            }

            window.location = '/contact-lenses?productName=' + this.searchTerm;
        },
        onSearchUpDown(value) {
            let newSearchIndex = value + this.selectedSearchIndex;
            if (newSearchIndex < -1) {
                return;
            }

            if (newSearchIndex > this.searchResult.length) {
                return;
            }

            this.selectedSearchIndex = newSearchIndex;
        },
        onSearchProduct(e) {
            if (this.searchTimeout) {
                window.clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            this.searchTimeout = window.setTimeout(() => {
                this.searchProduct(e);
            }, 400);
        },
        async searchProduct(e) {
            if (e.target.value.length < 3) {
                this.searchResult = [];
                this.searchResultCount = 0;
                return;
            }

            if (e.target.value != '') {
                this.isSearch = true;
            } else {
                this.isSearch = false;
            }

            if (e.target.value === '') {
                this.selectedSearchIndex = -1;
            }

            if (this.lastSearchTerm === e.target.value) {
                return;
            }

            this.lastSearchTerm = e.target.value;
            let search = {
                name: this.searchTerm,
                isPurchasable: 1,
                maxResults: 5
            };
            this.isLoadingSearchResults = true;

            if (this.searchController !== null) {
                this.searchController.cancel();
            }

            const searchController = this.$axios.CancelToken.source();
            let wasCanceled = false;
            try {
                this.searchController = searchController;
                let response = await this.$axios.$get('/product', {
                    params: search,
                    cancelToken: searchController.token
                });
                this.searchResult = this.formatSearchResults(response.data.filter(product => product.slug));

                this.searchResultCount = response.meta.totalCount;
                this.$store.dispatch('search/addSearchHistory', this.searchTerm);
                this.searchController = null;
            } catch (e) {
                // Prevent issues loading results
                wasCanceled = e?.message === 'canceled';
            }

            if (!wasCanceled) {
                this.isLoadingSearchResults = false;
            }
        },
        async getCategory(type, slug) {
            await this.getCategoryData({ slug: slug });
            await this.getProductData({ slug: slug });

            if (type == 9) {
                this.$router.push('/lens-replacement/DIY-lens');
            } else if (type == 1) {
                this.$router.push('/contact-lenses');
            } else {
                this.$router.push('/' + slug);
            }
        },
        shouldHideDropdown() {
            if(this.isHoveringRewards == true){
                this.hideDropdown();
                window.clearTimeout(this.isHoveringDropdownTimeout);
                return;
            }
            this.isHoveringRewards = false;

            if (this.isHoveringDropdownTimeout) {
                window.clearTimeout(this.isHoveringDropdownTimeout);
            }

            this.isHoveringMenuItem = false;
            this.isHoveringDropdownTimeout = window.setTimeout(() => {
                if ((!this.isHoveringDropdown && !this.isHoveringMenuItem) || this.isHoveringRewards) {
                    this.hideDropdown();
                }
            }, 200);
        },
        handleDropdown(name, lists, slug, type) {
            this.isHoveringMenuItem = true;

            if (name === 'LD Rewards'){
                this.isHoveringRewards = true;
                this.hideDropdown();
                return;
            }

            this.isHoveringRewards = false;
            this.getDropdown(name, lists, slug, type)
        },
        getDropdown(name, lists, slug, type) {
            if (this.clickedNavbarLink && this.clickedNavbarLink === name) {
                return;
            }

            this.choosenCategoryLists = lists;
            this.choosenCategoryLink = slug;
            this.choosenCategoryType = type;
            setTimeout(() => {
                if (this.isHoveringMenuItem) {
                    this.isDropdownShow = true;
                }
            }, 400);
        },
        hideDropdown() {
            this.isDropdownShow = false;
            this.isHoveringDropdown = false;
            this.isHoveringMenuItem = false;
        },
        onClickMobileNavButton() {
            this.showMobileNav = !this.showMobileNav;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/base-url.scss';
@import '~/assets/scss/variables/transitions.scss';

.badge-container {
    position: relative;
    .non-rx-badge {
        top: -24px;
        right: 60px;
        font-size: 11px;
        border-radius: 20px;
        background-color: #fbed8e;
        padding: 4px 6px;
        position: absolute;
    }
}

.badge-red {
    display: inline-block;
    margin-left: 5px;
    color: #ffffff;
    background-color: #c70b15;
    font-weight: bold;
    border-radius: 3px;
    padding: 7px 7px;
    font-size: .75em;
    line-height: .75em;
}

.mobile-favorites-link {
    display: none;
}

#navbarSupportedContent {
    ul {
        &.navbar-nav {
            gap: 5px;
        }
    }
}

.navbar-quick-actions-container {
    display: flex;
    gap: 25px;
    align-items: center;
}
.favorites-container {
    .favorites-dropdown {
        .favorites-length {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
        }
        .favorite-button {
            width: 300px;
            font-size: 1em;
            color: #ffffff;
            border: 0px;
            background-color: $color-blue;
            border-radius: 100px;
            padding: 5px 16px;
            font-weight: bold;
            opacity: .5;
            transition: $transition;
            &:hover {
                transition: $transition;
                opacity: 1;
            }
        }
        .favorite-item {
            padding: 18px 0;
            cursor: pointer;
            border-top: 1px solid #dce3e8;
            .favorite-name {
                font-size: 16px;
                font-weight: bold;
                line-height: 16px;
            }
            .favorite-color {
                font-size: 12px;
            }
            .favorite-price {
                font-size: 12px;
                color: #91a5b4;
            }
        }
            .third{
                border-bottom: 1px solid #dce3e8;
            }
    }
}
::v-deep {
        .btn {
            &.btn-secondary{

                &.dropdown-toggle{
                    border: none;
                    padding: 0;
                    background-color: #fff;
                }
            }
        }
    }
.btn-nav-mobile-search {
    display: none;
}


@media (max-width: $breakpoint2) {
    #navbarSupportedContent {
        display: none !important;
    }
}

#navbarSupportedContent {
    height: 60px;
    padding-left: 22px;
    justify-content: space-between;
}
.navbar-wrapper {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 40px;
    width: 100%;
    z-index: 1000;
}

.navbar-area {
    position: relative;
    z-index: 1000;
}

.dropdown-container {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.custom-container {
    padding: 0px;
}

.search-product:hover,
.selected.show-result,
.selected.search-product {
    background-color: $bg-light-grey;
}

.search-link {
    max-width: 75%;
}

.lens-replacement-subtitle {
    font-family: $font-primary;
    font-size: 12px;
}

.text-black {
    a {
        color: #000000;
    }
}
::v-deep {
    .ssr-carousel-dots {
        position: absolute !important;
        bottom: 32px;
        left: 32px;
        margin-top: 0px;
        align-items: center;
        .ssr-carousel-dot-icon {
            border: none;
            cursor: pointer;
            opacity: 1;
            background: #fff;
            height: 12px;
            width: 12px;
        }
        :not([disabled]) > .ssr-carousel-dot-icon {
            padding: 0 2px 2px;
            opacity: 0.7;
            height: 8px;
            margin-bottom: 2px;
            width: 8px;
        }
    }
}

.discover-sales-item {
    position: relative;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    transition: transform 0.2s;
    height: 200px;
    width: 300px;
    min-height: auto;;
    border-radius: 6px;
    &.vision-test-bg {
        margin-right: 16px;
        background-color: #ffffff;
        background-image: url($baseAssetUrl+'images/navbar/menu-vision-test-compressed.png');
        color: #000000;
    }
    &.app-download-bg {
        background-color: #000000;
        background-image: url($baseAssetUrl+'images/navbar/menu-app-compressed.png');
        .title, .subtitle {
            color: #ffffff;
        }
    }
    background-size: cover;
    &:hover {
        transform: scale(1.03);
    }
    .title {
        color: $color-black;
        font-family: $font-primary;
        font-weight: bold;
        font-size: 1.3125em;
        position: absolute;
        top: 16px;
        left: 16px;
        text-transform: uppercase;
        line-height: 1.1em;
        &.white {
            color: $color-white;
        }
    }
    .subtitle {
        text-align: left;
        color: $color-black;
        font-family: $font-primary;
        font-size: .875em;
        position: absolute;
        top: 40px;
        left: 16px;
        &.white {
            color: $color-white;
        }
    }
}

.black-card {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    color: #ffffff;
    font-family: $font-primary;
    font-weight: 900;
    padding-bottom: 0px;
    border-radius: 10px;
    padding: 0px;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.03);
    }
    p {
        display: block;
        font-size: 24px;
        width: 100%;
        padding: 20px;
        background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.5) 100%) 0% 0% no-repeat
            padding-box;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-shadow: 0px 3px 6px #00000029;
    }
    &:hover {
        p {
            background: transparent linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.7) 100%) 0% 0% no-repeat
                padding-box;
        }
    }
    &.autorefill-card {
        position: relative;
        height: auto;
        background-image: url($baseAssetUrl+'images/autorefill-nav-promo.png');
        .autorefill-title {
            font-family: $font-primary;
            font-weight: bold;
            position: absolute;
            font-size: 24px;
            top: 15px;
            left: 36px;
        }
        .autorefill-text {
            font-family: $font-primary;
            position: absolute;
            text-transform: none;
            top: 45px;
            left: 36px;
            font-size: 14px;
        }
    }
    &.lens-replacement {
        &.left {
            background-image: url($baseAssetUrl+'images/lens-replacement-menu-01.png');
        }
        &.right {
            background-image: url($baseAssetUrl+'images/lens-replacement-menu-02.png');
        }
        position: relative;
        height: auto;
        transition: transform 0.2s;
        &:hover {
            transform: scale(1.03);
        }
        .title {
            color: $color-black;
            font-family: $font-primary;
            font-weight: bold;
            text-align: left;
            position: absolute;
            line-height: 19px;
            font-size: 16px;
            top: 25px;
            left: 35px;
        }
        .text {
            margin-top: 15px;
            color: $color-black;
            font-family: $font-primary;
            position: absolute;
            font-weight: normal;
            text-transform: none;
            top: 55px;
            left: 35px;
            font-size: 12px;
        }
    }
    &.nav-image-card {
        position: relative;
        height: auto;
        transition: transform 0.2s;
        &:hover {
            transform: scale(1.03);
        }
        &.glasses-arrivals {
            height: 150px;
            width: 265px;
            background-image: url($baseAssetUrl+'images/navbar/glasses-new-arrivals.jpg');
            border-radius: 6px;
            background-size: cover;
        }
        &.glasses-popular {
            margin-right: 16px;
            height: 150px;
            width: 265px;
            background-image: url($baseAssetUrl+'images/navbar/glasses-best-sellers.jpg');
            border-radius: 6px;
            background-size: cover;
        }
        &.sunglasses-hyannis {
            margin-right: 16px;
            height: 200px;
            width: 300px;
            min-height: auto;
            background-image: url($baseAssetUrl+'images/navbar/sunglasses-hyannis.jpg');
            border-radius: 6px;
            background-size: cover;
        }
        &.rayban-sunglasses {
            height: 200px;
            width: 300px;
            min-height: auto;
            background-image: url($baseAssetUrl+'images/navbar/menu-rayban-compressed.png');
            border-radius: 6px;
            background-size: cover;
            color: #ffffff;
        }
        .title {
            font-family: $font-primary;
            font-weight: bold;
            position: absolute;
            font-size: 1.5em;
            top: 10px;
            left: 16px;
            &.black {
                color: $color-black;
            }
        }
        .text {
            font-family: $font-primary;
            font-weight: 300;
            position: absolute;
            text-transform: none;
            top: 45px;
            left: 16px;
            font-size: .875em;
            text-align: left;
            &.black {
                color: $color-black;
            }
        }
    }
}

.brand {
    background-color: #f1f8ff;
    img {
        opacity: 0.4;
    }
    &:hover {
        img {
            opacity: 1;
        }
    }
    .acuvue-contacts {
        max-height: 18px;
    }
    .air-optix {
        max-height: 25px;
    }
    .bausch-and-lomb {
        padding: 0px 6px;
        max-height: 12px;
    }
    .purevision {
        padding: 0px 6px;
    }
    .precision-1 {
        padding: 0px 6px;
    }
    .biofinity {
        max-height: 25px;
    }
    .clariti {
        max-height: 20px;
    }
    .dailies {
        max-height: 18px;
    }
    .focus {
        max-height: 20px;
    }
    .freshlook {
        max-height: 30px;
    }
}


@media (max-width: 1919px) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: .35vw;
            }
        }
    }
}

@media (max-width: 1615px) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: 0px;
            }
        }
    }
}

@media (max-width: 1585px) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: .3vw;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    #navbarSupportedContent {
        padding-left: 34px;
        ul {
            &.navbar-nav {
                gap: 10px;
            }
        }
    }
}

@media (max-width: 1535px) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: .3vw;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    #navbarSupportedContent {
        padding-left: 34px;
    }
}

@media (max-width: 1385px) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: .1vw;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .navbar-area .comero-nav .others-option .option-item .searchProduct .search-result {
        min-width: 500px;
        right: 0px;
    }

    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: .5vw;
            }
        }

        padding-left: 48px;
    }

    .discover-sales-item {
        width: calc(1vw * (200 / 13.66));
        height: calc(1vw * (300 / 13.66));
        &.vision-test-bg {
            margin-right: 8px;
        }
    }

    .badge-container {
        .non-rx-badge {
            right: auto;
            left: 130px;
        }
    }

    .black-card {
        min-height: auto;
        &.lens-replacement {
            &.left {
                height: calc(1vw * (200 / 13.66));
            }
            &.right {
                height: calc(1vw * (200 / 13.66));
            }
            .title {
                top: 15px;
                left: 15px;
            }
            .text {
                top: 43px;
                left: 15px;
            }
        }
        &.nav-image-card {
            &.sunglasses-hyannis {
                margin-right: 8px;
                width: calc(1vw * (300 / 13.66));
                height: calc(1vw * (200 / 13.66));
            }
            &.rayban-sunglasses {
                width: calc(1vw * (300 / 13.66));
                height: calc(1vw * (200 / 13.66));
            }
            &.glasses-arrivals {
                min-height: auto;
                height: calc(1vw * (200 / 13.66));
                width: calc(1vw * (265 / 13.66));
            }
            &.glasses-popular {
                min-height: auto;
                height: calc(1vw * (200 / 13.66));
                width: calc(1vw * (265 / 13.66));
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    #navbarSupportedContent {
        ul {
            &.navbar-nav {
                gap: 0px;
            }
        }
    }

    .discover-sales-item {
        font-size: 12px;
        width: calc(1vw * (260 / 12.8));
        height: calc(1vw * (173 / 12.8));
    }

    .black-card {
        font-size: 14px;
        &.nav-image-card {
            &.sunglasses-hyannis {
                margin-right: 8px;
                width: calc(1vw * (260 / 12.8));
                height: calc(1vw * (173 / 12.8));
            }
            &.rayban-sunglasses {
                width: calc(1vw * (260 / 12.8));
                height: calc(1vw * (173 / 12.8));
            }
        }
    }
}

@media (max-width: 1215px) {
    #navbarSupportedContent {
        padding-left: 20px;
    }
}

a.search-result {
    position: relative;
    display: block;
}

@media (max-width: $breakpoint2) {
    .navbar-wrapper {
        position: sticky;
        top: 0px;
    }

    .navbar-area .comero-nav .others-option .option-item .searchProduct .search-result {
        min-width: auto;
        right: auto;
    }

    .mobile-scroll {
        position: fixed;
        top: 0;
    }

    .navbar-wrapper {
        border-bottom: solid $color-grey-border 1px;
        box-shadow: none;
        &.search-open {
            z-index: 1300;
        }
    }

    .hidden-top-panel {
        .navbar-wrapper {
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .navbar-area {
        .comero-nav {
            .navbar {
                .mobile-shopping-cart {
                    top: 5px;
                    img {
                        max-width: 20px;
                    }
                }
                .mobile-favorites-link {
                    display: block;
                    position: absolute;
                    right: 75px;
                    img {
                        max-width: 20px;
                    }
                    .count {
                        position: absolute;
                        top: -5px;
                        right: -10px;
                        font-size: 12px;
                        border-radius: 100px;
                        background-color: #cb1f2b;
                        color: #ffffff;
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                    }
                }
                .btn-nav-mobile-search {
                    display: block;
                    position: absolute;
                    right: 110px;
                    background-color: transparent;
                    border: 0px;
                    img {
                        max-width: 20px;
                    }
                }
            }
        }
    }
}


</style>
