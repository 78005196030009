<template>
    <!-- Start Top Panel Area -->
    <!-- <div class="top-panel" :class="{ 'global-banner-sale light-bg': isSaleActive }"> -->
    <div>
        <client-only>
            <div v-if="showIosAppBanner && !clickedHideIosBanner" class="ios-app-install d-flex d-md-none justify-content-between">
                <div class="app-description d-flex flex-row align-items-center justify-content-between">
                    <font-awesome-icon
                        icon="fa-solid fa-xmark"
                        aria-label="Hide Install App Notification"
                        size="xl"
                        :style="{color: '#91a5b4'}"
                        class="mr-3"
                        @click="hideIosAppBanner"
                    />
                    <img class="app-img mr-3" :src="formatAssetUrl('images/ld-appstore@2x.png')" alt="LensDirect App" @click="goToAppStore" />
                    <div class="d-flex flex-column" @click="goToAppStore">
                        <div class="app-name bold">LensDirect app</div>
                        <div class="app-subtitle">Free - AppStore</div>
                    </div>
                </div>
                <div class="install-button" @click="goToAppStore">
                    <div class="install">Install</div>
                </div>
            </div>
        </client-only>
        <div class="top-panel" :class="currentGlobalBannerClasses">

            <div v-if="currentGlobalBanner" v-html="currentGlobalBanner.styleHtml"></div>
            <div class="container--version2">
                <GlobalBannerSaleCustom
                    v-if="currentGlobalBanner"
                    :globalBanner="currentGlobalBanner"
                    @hasLightBg="onHasLightBg"
                    @index="onGlobalBannerIndex"
                    @bannerKey="bannerKey = $event"
                />
                <GlobalBannerSaleAlt :globalBannerProductType="globalBannerProductType" v-else-if="isSaleActive" />
                <GlobalBannerSale :globalBannerProductType="globalBannerProductType" v-else />
                <div v-if="this.hideAuth != true" class="account">
                    <div class="help-link">
                        <a href="/profile/order-history" @click.prevent="onClickReOrderLenses" class="reorder-header-link">
                            Reorder Contacts
                        </a>
                    </div>
                    <span class="separator ml-2"> |</span>
                    <div @mouseover="showMenu('help')" @mouseleave="shouldHideDropdown('help')" class="help-dropdown-wrapper">
                        <client-only>
                        <b-dropdown
                            ref="helpDropdown"
                            class="help-dropdown"
                            size="sm"
                            toggle-class="text-decoration-none help-link"
                            no-caret
                            center
                        >
                            <template #button-content>
                                <span>Help</span>
                            </template>
                            <b-dropdown-text style="width: 272px">
                                <div class="help-menu-wrapper">
                                    <h6>
                                        Have a question?
                                    </h6>
                                    <ContactOptionsHeader />
                                    <div class="help-menu-links">
                                        <a
                                            v-if="!currentUser"
                                            href="/guest/check-order-status"
                                            @click.prevent="goToUrl('/guest/check-order-status')"
                                        >
                                            Track Order
                                        </a>
                                        <a
                                            v-else
                                            href="/profile/track-my-order"
                                            @click.prevent="goToUrl('/profile/track-my-order')"
                                        >
                                            Track Order
                                        </a>
                                        <strong>&middot;</strong>
                                        <a href="/help" @click.prevent="goToUrl('/help')">Contact Us</a>
                                    </div>
                                </div>
                            </b-dropdown-text>
                        </b-dropdown>
                        </client-only>
                    </div>
                    <span class="separator ml-2"> |</span>
                    <div @mouseover="showMenu('account')" @mouseleave="shouldHideAccount">
                        <client-only>
                            <b-dropdown
                                ref="accountDropdown"
                                class="account-list"
                                :class="{ 'logged-out': !currentUser }"
                                size="sm"
                                toggle-class="text-decoration-none account-button"
                            >
                                <template #button-content>
                                    <span :class="currentUser != null ? 'logged-in-text' : ''">{{ currentUser != null ? currentUser.firstName : 'My Account' }}</span>
                                </template>

                                <b-dropdown-text v-if="!currentUser" style="width: 324px">
                                    <LoginForm
                                        @forgotPassword="onClickForgotPassword"
                                        @createAccount="onClickCreateAccount"
                                        @fetchingUser="onLoggedIn"
                                        @fbLoggedIn="onFacebookLogin"
                                    >
                                        <template #title>
                                            Sign in
                                        </template>
                                    </LoginForm>
                                </b-dropdown-text>
                                <b-dropdown-item
                                    to="/profile/account-snapshot"
                                    class="customer-name-link"
                                    v-if="currentUser != null"
                                    >{{ currentUser.firstName + ' ' + currentUser.lastName }}</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/account-snapshot" v-if="currentUser != null">
                                    Account Snapshot</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/order-history" v-if="currentUser != null"
                                    >Order History</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/track-my-order" v-if="currentUser != null"
                                    >Track My Order</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/giftcards" v-if="currentUser != null">Gift Cards</b-dropdown-item>
                                <b-dropdown-item to="/profile/invite-earn" v-if="currentUser != null"
                                    >Rewards</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/payment-methods" v-if="currentUser != null"
                                    >Payment Methods</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/prescriptions" v-if="currentUser != null"
                                    >Prescriptions</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/account-settings" v-if="currentUser != null"
                                    >Account Settings</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/address-book" v-if="currentUser != null"
                                    >Address Book</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/autorefills" v-if="currentUser != null"
                                    >Subscriptions</b-dropdown-item
                                >
                                <b-dropdown-item to="/profile/logout" v-if="currentUser != null">Logout</b-dropdown-item>
                            </b-dropdown>
                        </client-only>
                    </div>
                </div>
            </div>
            <SidebarPanel />
            <ReorderContactsLogin />
            <LoginCaptcha v-if="!currentUser" />
        </div>
    </div>
    <!-- End Top Panel Area -->
</template>

<script>
const APP_DOWNLOAD_URL = 'https://apps.apple.com/us/app/lensdirect/id1627023741';

import { mapActions } from 'vuex';
import { determineProductType } from '@/utils/product-type-utils';
import SidebarPanel from '@/layouts/SidebarPanel';
import imageUrlFormatterMixin from '@/mixins/image-url-formatter';
import LoginForm from '@/components/auth-form/LoginForm';
import notificationsMixin from '@/mixins/notifications';
import ContactOptionsHeader from '@/components/ContactOptionsHeader';
import productTypes from '@/constants/product-types';
import GlobalBannerSale from '@/components/GlobalBannerSale';
import GlobalBannerSaleAlt from '@/components/GlobalBannerSaleAlt';
import ReorderContactsLogin from '@/components/ReorderContactsLogin';
import GlobalBannerSaleCustom from '@/components/GlobalBannerSaleCustom';
import LoginCaptcha from '@/components/LoginCaptcha';
import saleActiveMixin from '@/mixins/sale-active-mixin';
import customerContentTypes from '@/constants/customer-content-types';
import checkMobile, { isIosDevice } from '@/utils/check-mobile';
import formatImageMixin from '@/mixins/image-url-formatter';
import storage from '@/utils/storage';

export default {
    name: 'AppHeader',
    mixins: [formatImageMixin, imageUrlFormatterMixin, notificationsMixin, saleActiveMixin],
    components: {
        SidebarPanel,
        LoginForm,
        ContactOptionsHeader,
        GlobalBannerSale,
        ReorderContactsLogin,
        GlobalBannerSaleAlt,
        LoginCaptcha,
        GlobalBannerSaleCustom
    },
    data() {
        return {
            clickedHideIosBanner: false,
            downloadUrl: APP_DOWNLOAD_URL,
            bannerKey: null,
            hasLightBg: null,
            currentGlobalBannerIndex: 0,
            isHovering: {
                help: {
                    refKey: 'helpDropdown',
                    dropdown: false,
                    menuItem: false,
                    dropdownTimeout: false
                },
                account: {
                    refKey: 'accountDropdown',
                    dropdown: false,
                    menuItem: false,
                    dropdownTimeout: false
                }
            }
        };
    },
    props: ['hideAuth'],
    computed: {
        showIosAppBanner() {
            const showBanner = storage.get('showIosAppBanner') == null || storage.get('showIosAppBanner') == true;
            return !this.clickedHideIosBanner && checkMobile() && isIosDevice() && showBanner;
        },
        isNewCustomer() {
            return this.$store.getters['auth/isNewCustomer'];
        },
        initialBannerKey() {
            if (!this.currentGlobalBanner) {
                return '';
            }

            for (let globalBannerData of this.currentGlobalBanner.componentData) {
                if (this.isNewCustomer && globalBannerData.customerType === customerContentTypes.RETURNING.id) {
                    continue;
                }

                if (!this.isNewCustomer && globalBannerData.customerType === customerContentTypes.NEW.id) {
                    continue;
                }

                return globalBannerData.componentKey;
            }

            return '';
        },
        currentGlobalBanner() {
            return this.$store.getters['uiComponents/currentGlobalBanner'](this.$route);
        },
        currentGlobalBannerClasses() {
            if (!this.currentGlobalBanner) {
                return {};
            }

            let classes = [];

            if (this.currentGlobalBannerIndex !== null) {
                classes.push(this.currentGlobalBanner.classNamePrefix + '_' + (this.bannerKey ? this.bannerKey : this.initialBannerKey));
            }

            if (this.getHasLightBg()) {
                classes.push('light-bg');
            }

            return classes;
        },
        isSaleCategory() {
            return this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.ACCESSORIES.id ||
                this.globalBannerProductType === 0;
        },
        globalBannerProductType() {
            return determineProductType(this.$route, this.$store.state);
        },
        globalBannerPercent() {
            if (this.globalBannerProductType === productTypes.GLASSES.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.READERS.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return '15%';
            }

            return '15%';
        },
        globalBannerUseCodeContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'Use code:';
            }

            return '';
        },
        globalBannerInitialMobileContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return '';
            }

            return 'New Customer Offer!';
        },
        globalBannerInitialContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'Limited time offer! Take';
            }

            return 'New Customer Offer!';
        },
        globalBannerIncludeFreeShippingContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return '+ free shipping';
            }

            return '';
        },
        globalBannerCouponCode() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'EYE20';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return 'SUN20';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return 'REPLACE20';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return 'CONTACTS15';
            }

            return 'SAVE15';
        },
        globalBannerSaveOnProduct() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'glasses';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return 'sunglasses';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return 'lens replacement';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return 'contacts';
            }

            return 'all orders';
        },
        currentRoute() {
            return this.$route;
        },
        currentUser() {
            let currentUser = this.$store.getters['auth/currentUser'];
            if (!currentUser) {
                return null;
            }

            if (!currentUser.customer) {
                return null;
            }

            return currentUser.customer;
        },
        isShoppingCartWindowOpen() {
            return this.$store.state.sidebar.isNavOpen;
        },
        isSaleActive() {
            if (!this.isSaleCategory) {
                return false;
            }

            return this.getIsSaleActive(
                new Date('2024-06-14T00:00:00-04:00'),
                new Date('2024-06-21T02:59:59-04:00')
            );
        }
    },
    methods: {
        goToAppStore(){
            storage.set('showIosAppBanner', false);
            window.location = APP_DOWNLOAD_URL;
        },
        hideIosAppBanner(){
            this.clickedHideIosBanner = true;
            storage.set('showIosAppBanner', false);
        },
        onGlobalBannerIndex(index) {
            this.currentGlobalBannerIndex = index;
        },
        getHasLightBg() {
            if (this.hasLightBg) {
                return true;
            }

            if (!this.currentGlobalBanner) {
                return false;
            }

            if (typeof this.currentGlobalBanner.componentData[this.currentGlobalBannerIndex] === 'undefined') {
                return false;
            }

            return this.currentGlobalBanner.componentData[this.currentGlobalBannerIndex].isLightBg;
        },
        onHasLightBg(status) {
            this.hasLightBg = status;
        },
        onClickReOrderLenses() {
            if (this.currentUser) {
                this.$router.push('/profile/order-history');
                return;
            }

            this.$store.dispatch('setShowReorderLogin', true);
        },
        onFacebookLogin() {
            window.location = '/facebook-redirect';
        },
        goToUrl(url) {
            this.$router.push(url);
            this.hideDropdown('help');
        },
        onClickForgotPassword() {
            this.$router.push('/user/forgot-password');
        },
        onClickCreateAccount() {
            this.$router.push('/user/register');
        },
        onLoggedIn() {
            this.successMessage('Welcome back!');
            this.$router.push('/profile/account-snapshot');
        },
        showMenu(menuName) {
            let refKey = this.isHovering[menuName].refKey;
            this.isHovering[menuName].menuItem = true;
            this.$refs[refKey].visible = true;
        },
        shouldHideAccount(event){
            if(event.toElement == null){
                return;
            } else {
                this.shouldHideDropdown('account');
            }
        },
        shouldHideDropdown(menuName) {
            if (this.isHovering[menuName].dropdownTimeout) {
                window.clearTimeout(this.isHovering[menuName].dropdownTimeout);
            }

            this.isHovering[menuName].menuItem = false;
            this.isHovering[menuName].dropdownTimeout = window.setTimeout(() => {
                if (!this.isHovering[menuName].dropdown && !this.isHovering[menuName].menuItem) {
                    this.hideDropdown(menuName);
                }
            }, 200);
        },
        hideDropdown(menuName) {
            let refKey = this.isHovering[menuName].refKey;
            if (typeof this.$refs[refKey] !== 'undefined') {
                this.$refs[refKey].visible = false;
            }

            this.isHovering[menuName].dropdown = false;
            this.isHovering[menuName].menuItem = false;
        },
        ...mapActions(['getUser']),

        async loginUser() {
            let requestBody = {
                username: this.email,
                password: this.password
            };
            await this.login(requestBody).then(() => {
                this.$router.push('/admin/account-snapshot');
            });
        }
    },
    watch: {
        currentGlobalBanner() {
            if (typeof window === 'undefined') {
                return;
            }

            this.currentGlobalBannerIndex = 0;
        },
        $route(newRoute) {
            if (typeof window === 'undefined') {
                return;
            }

            let timeout = window.setTimeout(() => {
                this.$store.dispatch('setGlobalBannerProductTypeId', 0);
            }, 250);

            this.$store.dispatch('setGlobalBannerProductTypeTimeout', timeout);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/base-url.scss';

.logged-in-text {
    display: inline-block;
    color: #ffffff;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.help-dropdown-wrapper {
    min-height: 28px;
    &::v-deep {
        .help-menu-wrapper {
            .footer-contact {
                li {
                    img {
                        transition: transform .2s;
                    }
                    &:hover {
                        img {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
}
::v-deep {
    .customer-name-link {
        a {
            max-width: 240px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
.ios-app-install{
    background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #D1D1D1 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #D1D1D1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    padding: 10px 15px;
    .app-img{
        max-height: 35px;
    }
    .app-name{
        font-size: 18px;
        line-height: 12px;
        color: #000000;
    }
    .app-subtitle{
        margin-bottom: -4px;
        color: #000000;
    }
    .install-button{
        background-color: #78A4E7;
        border-radius: 3px;
        padding: 4px 20px;
        color: #fff;
        border: none;
        .install{
            margin-top: 2px;
        }
    }
}
.top-panel {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1200;
    min-height: 42px;
    &.light-bg {
        color: #000000;
        .account {
            color: #000000;
            .reorder-header-link {
                color: #000000;
                img {
                    filter: invert(1);
                }
            }
            &::v-deep {
                .dropdown-toggle {
                    color: #000000;
                    .logged-in-text {
                        color: #000000;
                    }
                }
            }
            .separator {
                color: #000000;
            }
        }
    }
    &.global-banner-sale {
        background-color: #D5E3FD;
        &::v-deep {
            .panel-content {
                p {
                    color: #1E2D42;
                }
            }
        }
    }
}

.top-panel .account {
    .separator {
        color: #fff;
        font-size: 14px;
        margin-right: 8px;
    }
    .reorder-header-link {
        color: #ffffff;
    }
    ::v-deep {
        .help-link {
            position: relative;
            color: #fff;
            font-size: 14px;
            margin-right: 8px;
            padding: 3px 8px;
            margin-right: 0px;
            background-color: transparent;
            border: 0px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 3px;
            }
            img {
                width: 20px;
            }
        }
    }
    .account-list {
        ::v-deep {
            .dropdown-menu {
                top: 41px !important;
                border-radius: 10px !important;
                box-shadow: 0px 4px 6px #00000012;
            }
            .login-form-container {
                .alert-danger {
                    font-size: 14px;
                    padding: 10px;
                    font-weight: 600;
                }
                h1 {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.2em;
                    padding: 20px 0px;
                }
                .form-control {
                    font-size: 14px;
                }
                .form-label-group {
                    label {
                        font-size: 14px;
                        line-height: 22px;
                    }
                    input:not(:placeholder-shown) ~ label {
                        font-size: 12px;
                    }
                }
                .btn-forgot-password {
                    font-size: 12px;
                    width: auto;
                    padding-top: 0px;
                    padding-bottom: 5px;
                }
                .btn-facebook {
                    font-size: 16px;
                    .fa-facebook {
                        font-size: 20px;
                        top: 17px;
                    }
                }
                .btn-amazon-login {
                    font-size: 16px;
                }
                button {
                    &.btn-outline[type='submit'] {
                        font-size: 16px;
                    }
                }
                .or-separator-container {
                    margin-top: 12px;
                    margin-bottom: 18px;
                }
                .create-account-link-container {
                    font-size: 14px;
                    margin-top: 10px;
                    a {
                        background-color: #ffffff !important;
                        padding: 0px !important;
                        padding-left: 5px !important;
                    }
                }
            }
        }
    }
    ::v-deep {
        .dropdown-toggle {
            display: flex;
            align-items: center;
            padding: 3px 8px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 3px;
            }
            &.account-button {
                position: relative;
                z-index: 10020;
            }
        }
        .help-dropdown {
            ul {
                &.dropdown-menu {
                    transform: translate3d(-80px, 24px, 0px) !important;
                    left: -33px !important;
                    top: 17px !important;
                    border-radius: 10px !important;
                    box-shadow: 0px 4px 6px #00000012;
                    &::before {
                        position: absolute;
                        content: '';
                        bottom: -25px;
                        left: 50%;
                        top: -7px;
                        margin-left: -7px;
                        width: 14px;
                        height: 14px;
                        background-color: #ffffff;
                        border: solid $color-grey-border 1px;
                        border-bottom: 0px;
                        border-right: 0px;
                        transform: rotate(45deg);
                    }
                }
            }
            .help-menu-wrapper {
                h6 {
                    font-size: 18px;
                    line-height: 1.2em;
                    text-align: center;
                    padding-top: 10px;
                    padding-bottom: 20px;
                }
                .help-menu-links {
                    display: flex;
                    font-size: 14px;
                    justify-content: center;
                    gap: 10px;
                    padding-bottom: 5px;
                    font-weight: 600;
                }
                .footer-contact {
                    justify-content: center;
                    margin-bottom: 25px;
                    li {
                        &::before {
                            display: none;
                        }
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100px;
                        height: 100px;
                        a {
                            gap: 20px;
                            height: auto;
                        }
                        h6 {
                            font-size: 14px !important;
                            padding: 0px;
                        }
                        img {
                            max-height: 32px;
                            max-width: 32px;
                        }
                    }
                }
            }
        }
        .show {
            .dropdown-toggle {
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 3px;
                &.account-button {
                    &::before {
                        position: absolute;
                        content: '';
                        bottom: -26px;
                        left: 50%;
                        margin-left: -7px;
                        width: 14px;
                        height: 14px;
                        background-color: $bg-light-blue;
                        border: solid $color-grey-border 1px;
                        border-bottom: 0px;
                        border-right: 0px;
                        transform: rotate(45deg);
                    }
                }
            }
            &.account-list {
                &.logged-out {
                    .dropdown-toggle {
                        &.account-button {
                            &::before {
                                background-color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
    .account-list.logged-out {
        ::v-deep {
            .dropdown-menu {
                background-color: #ffffff;
            }
        }
    }
}

.top-panel {
    ::v-deep {
        .dropdown-item {
            &.active {
                background-color: transparent;
            }
        }
    }
}
@media (max-width: $breakpoint2) {
    .top-panel {
        position: relative;
        z-index: 1200;
    }
}

@media (max-width: $breakpoint8) {
    .top-panel {
        .panel-content {
            p {
                font-size: 13px;
            }
        }
    }
}

@media (max-width: $breakpoint9) {
    .top-panel {
        .panel-content {
            p {
                font-size: 12px;
            }
        }
    }
}
</style>
