<template>
    <div class="panel-content" :class="{ 'cyber-monday-sale-banner': isCyberMondaySaleActive }">
        <ssr-carousel
            show-arrows
            v-model="loopedIndex"
            :key="globalBanner.id"
            :loop="true"
            :slides-per-page="1"
            @change="onBannerChange"
            @release="onRelease"
            @press="onPress"
        >
            <div
                v-for="(banner, index) in banners" :key="index + '_' + globalBanner.id + '_' + banner.componentKey"
                class="slide"
                @click="onClickBanner"
            >
                <CustomHtmlGlobalBanner
                    v-if="banner.type === 'global_banner'"
                    :banner="banner"
                />
                <TemplatedGlobalBanner
                    v-if="banner.type === 'templated_global_banner'"
                    :banner="banner"
                />
            </div>
        </ssr-carousel>

        <div class="d-none" v-if="currentGlobalPopup" v-html="currentGlobalPopup.styleHtml"></div>
        <CustomHtmlGlobalBannerModal
            v-if="currentGlobalPopup && currentGlobalBannerPopupType === 'global_popup'"
            :currentGlobalPopupKey="currentGlobalPopupKey"
            :currentGlobalPopup="currentGlobalPopup"
            :globalBannerModalOpen="globalBannerModalOpen"
            @close="toggleModalOpen(false)"
        />
        <TemplatedGlobalBannerModal
            v-if="currentGlobalPopup && currentGlobalBannerPopupType === 'templated_global_popup'"
            :currentGlobalPopupKey="currentGlobalPopupKey"
            :currentGlobalPopup="currentGlobalPopup"
            :globalBannerModalOpen="globalBannerModalOpen"
            :currentGlobalBannerPopupComponentData="currentGlobalBannerPopupComponentData"
            @close="toggleModalOpen(false)"
        />
    </div>
</template>

<script>
import objectFactory from '@/utils/object-factory';
import customerContentTypes from '@/constants/customer-content-types';
import { parseContentText } from '@/utils/ui-components';
import saleActiveMixin from '@/mixins/sale-active-mixin';

import CustomHtmlGlobalBannerModal from '@/components/modals/CustomHtmlGlobalBannerModal';
import TemplatedGlobalBannerModal from '@/components/modals/TemplatedGlobalBannerModal';
import CustomHtmlGlobalBanner from '@/components/global-banner/CustomHtmlGlobalBanner';
import TemplatedGlobalBanner from '@/components/global-banner/TemplatedGlobalBanner';

export default {
    mixins: [saleActiveMixin],
    components: {
        TemplatedGlobalBannerModal,
        CustomHtmlGlobalBannerModal,
        CustomHtmlGlobalBanner,
        TemplatedGlobalBanner
    },
    props: {
        globalBanner: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.$emit('hasLightBg', this.hasLightBg);
        this.$emit('bannerKey', this.currentBannerKey);
        this.initLoopTimeout();
    },
    destroyed() {
        if (this.loopTimeout) {
            window.clearTimeout(this.loopTimeout);
            this.loopTimeout = null;
        }
    },
    data() {
        return {
            currentIndex: 0,
            loopedIndex: 0,
            pressTimeout: null,
            isPressed: false,
            loopTimeout: null
        }
    },
    computed: {
        isCyberMondaySaleActive() {
            return this.getIsSaleActive(
                new Date('2024-12-02T03:00:00-05:00'),
                new Date('2024-12-03T02:59:59-05:00')
            );
        },
        currentGlobalPopupKey() {
            if (!this.currentGlobalPopup) {
                return '';
            }

            try {
                return this.currentGlobalPopup.componentData[0].componentKey;
            } catch (e) {
                return '';
            }
        },
        currentBannerKey() {
            try {
                return this.banners[this.currentIndex].componentKey;
            } catch (e) {
                return null;
            }
        },
        isNewCustomer() {
            return this.$store.getters['auth/isNewCustomer'];
        },
        currentGlobalBanner() {
            return this.banners[this.currentIndex];
        },
        currentGlobalPopup() {
            if (!this.currentGlobalBanner) {
                return null;
            }

            if (!this.currentGlobalBanner?.globalPopupId) {
                return null;
            }

            const globalPopupId = this.currentGlobalBanner?.globalPopupId;
            const currentGlobalPopup = this.globalPopups.find((globalPopup) => {
                if (globalPopup.id === globalPopupId) {
                    return globalPopup;
                }
            });

            return currentGlobalPopup ? currentGlobalPopup : null;
        },
        currentGlobalBannerPopupType() {
            return this.currentGlobalBannerPopupComponentData?.type;
        },
        currentGlobalBannerPopupComponentData() {
            try {
                return this.currentGlobalPopup?.componentData[0];
            } catch (e) {
                return null;
            }
        },
        globalBannerModalOpen() {
            return this.$store.state.globalBannerModalOpen;
        },
        hasLightBg() {
            if (this.currentIndex === null) {
                return false;
            }

            if (typeof this.banners[this.currentIndex] === 'undefined') {
                return false;
            }

            return this.banners[this.currentIndex].isLightBg;
        },
        banners() {
            let banners = [];
            const componentData = objectFactory.deepCopy(this.globalBanner.componentData);
            for (let globalBannerData of componentData) {
                if (this.isNewCustomer && globalBannerData.customerType === customerContentTypes.RETURNING.id) {
                    continue;
                }

                if (!this.isNewCustomer && globalBannerData.customerType === customerContentTypes.NEW.id) {
                    continue;
                }

                if (globalBannerData.type === 'global_banner') {
                    globalBannerData.desktopContent = this.parseBannerContent(globalBannerData, 'desktopContent');
                    globalBannerData.mobileContent = this.parseBannerContent(globalBannerData, 'mobileContent');
                }

                banners.push(globalBannerData);
            }

            return banners;
        },
        globalPopups() {
            return this.$store.state.uiComponents.globalPopups;
        }
    },
    methods: {
        getNextSlide() {
            return this.loopedIndex + 1;
        },
        initLoopTimeout() {
            if (this.loopTimeout) {
                window.clearTimeout(this.loopTimeout);
                this.loopTimeout = null;
            }

            if (this.banners.length <= 1) {
                return;
            }

            this.loopTimeout = window.setTimeout(() => {
                this.loopedIndex++;
            }, 10000);
        },
        onRelease() {
            if (this.isPressed) {
                this.onClickBanner();
            }

            if (this.pressTimeout) {
                window.clearTimeout(this.pressTimeout);
                this.pressTimeout = null;
                this.isPressed = false;
            }
        },
        onPress() {
            this.isPressed = true;
            this.pressTimeout = window.setTimeout(() => {
                this.isPressed = false;
            }, 100);
        },
        onClickBanner() {
            if (!this.currentGlobalPopup) {
                return;
            }

            this.toggleModalOpen(true);
        },
        toggleModalOpen(status) {
            this.$store.dispatch('setGlobalBannerModalOpen', status);

            if (status && this.loopTimeout) {
                window.clearTimeout(this.loopTimeout);
                this.loopTimeout = null;
            } else if(!status && !this.loopTimeout) {
                this.initLoopTimeout();
            }
        },
        onBannerChange(e) {
            this.$emit('index', e.index);
            this.currentIndex = e.index;
            this.initLoopTimeout();
        },
        parseBannerContent(componentData, contentDevice) {
            let content = componentData[contentDevice];
            return parseContentText(content, componentData.highlightColor);
        }
    },
    watch: {
        globalBanner() {
            this.currentIndex = 0;
            this.loopedIndex = 0;
        },
        currentBannerKey(newValue) {
            this.$emit('bannerKey', newValue);
        },
        $route(newRoute) {
            if (typeof window === 'undefined') {
                return;
            }

            this.currentIndex = 0;
            this.loopedIndex = 0;
        },
        hasLightBg() {
            this.$emit('hasLightBg', this.hasLightBg);
        }
    }
}
</script>

<style lang="scss">
@import '~/assets/scss/variables/breakpoints.scss';

.panel-content {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    line-height: normal;
    color: #ffffff;
    a {
        display: inline;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .cursor-hand {
        cursor: pointer;
    }
    .emoji {
        font-family: 'Noto Color Emoji', sans-serif;
    }
    &.cyber-monday-sale-banner {
        .emoji {
            display: inline-block;
            vertical-align: top;
        }
    }
    .hover-underline {
        cursor: pointer;
    }
    &:hover {
        .hover-underline {
            text-decoration: underline;
        }
    }
    .ssr-carousel {
        .ssr-carousel-left-icon, .ssr-carousel-right-icon {
            background-color: transparent;
        }
        .ssr-carousel-left-icon {
            &::before {
                border-width: 6px 9px 6px 0px;
            }
        }
        .ssr-carousel-right-icon {
            &::before {
                border-width: 6px 0px 6px 9px;
            }
        }
    }
}

.light-bg {
    .panel-content {
        .ssr-carousel {
            .ssr-carousel-left-icon {
                &::before {
                    border-color: transparent #000000 transparent transparent;
                }
            }
            .ssr-carousel-right-icon {
                &::before {
                    border-color: transparent transparent transparent #000000;
                }
            }
        }
    }
}

.custom-global-banner-sale-modal {
    text-align: center;
    h2 {
        font-size: 36px;
        text-align: center;
        line-height: 1.2em;
    }
    p {
        font-size: 24px;
        &.small {
            font-size: 12px;
        }
    }
    a {
        display: inline;
        &:hover {
            text-decoration: underline;
        }
    }
    .modal-content {
        .emoji {
            font-family: 'Noto Color Emoji', sans-serif;
        }
        .close-button {
            top: 0px;
            right: 0px;
            padding: 20px;
            font-size: 24px !important;
            &:hover {
                opacity: .5;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .panel-content {
        width: 100%;
        .emoji {
            font-family: sans-serif;
        }
        &.cyber-monday-sale-banner {
            .custom-global-banner-text {
                &.hidden-desktop {
                    display: flex !important;
                    justify-content: center;
                    gap: 5px;
                }
            }
            .emoji {
                display: inline;
                line-height: 20px;
            }
        }
    }

    .custom-global-banner-sale-modal {
        .font-sm-mobile {
            font-size: 16px !important;
        }
    }
}
</style>
