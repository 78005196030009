<template>
    <div class="custom-html-global-banner-component">
        <div class="hidden-mobile custom-global-banner-text" v-html="banner.desktopContent"></div>
        <div class="hidden-desktop custom-global-banner-text" v-html="banner.mobileContent"></div>
    </div>
</template>

<script>
export default {
    props: {
        banner: {
            type: Object,
            required: true
        }
    }
}
</script>
