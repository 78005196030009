export const parseContentText = (content, highlightColor) => {
    let highlightStyle = '';
    if (highlightColor) {
        highlightStyle = 'color: ' + highlightColor + ';';
    }

    content = content.replace(/\[highlight\]/g, '<span style="font-weight: bold; ' + highlightStyle + '">');
    content = content.replace(/\[\/highlight\]/g, '</span>');

    content = content.replace(/\[emoji\]/g, '<span class="emoji">');
    content = content.replace(/\[\/emoji\]/g, '</span>');

    return content;
};
