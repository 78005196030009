<template>
    <Modal
        :value="globalBannerModalOpen"
        :additionalDialogClass="currentGlobalPopup.classNamePrefix + '_' + currentGlobalPopupKey + ' custom-global-banner-sale-modal templated-global-banner-modal'"
        @close="toggleModalOpen(false)"
        :centered="!isMobile"
        :additionalContentClass="additionalContentClass"
        :noFade="isMobile"
    >
        <div class="templated-global-banner-modal-header">
            <div class="templated-global-banner-text-content">
                <div class="templated-global-banner-expires-date templated-global-popup-text-color2">
                    <span class="templated-global-banner-emoji-wrapper hidden-desktop">{{ emoji }}</span> Expires {{ expirationDate }}
                </div>
                <div class="templated-global-banner-header-text templated-global-popup-text-color1">
                    {{ headerText }}
                </div>
                <div class="templated-global-banner-header-subtext templated-global-popup-text-color3">
                    {{ headerSubtext }}
                </div>
            </div>
            <div class="templated-global-banner-emoji-wrapper hidden-mobile">
                {{ emoji }}
            </div>
        </div>
        <div class="templated-global-banner-modal-content">
            <div class="templated-global-banner-discounts templated-global-popup-text-color2">
                <div
                    v-for="(percentOffTextRecord, index) in percentOffText"
                    :key="index"
                >
                    <strong class="templated-global-popup-text-color1">{{ percentOffTextRecord.percentOff.content }}% off</strong> {{ percentOffTextRecord.productType.content }}
                </div>
                <div class="templated-global-banner-promo-code templated-global-popup-text-color1">
                    <div class="templated-global-popup-promo-code-bg-color1">
                        Use code:
                    </div>
                    <div class="templated-global-popup-promo-code-bg-color2">
                        {{ promoCode }}
                    </div>
                </div>
            </div>
            <div class="templated-global-banner-loyalty templated-global-popup-text-color2">
                <div class="templated-global-banner-loyalty-points">
                    <strong class="templated-global-popup-text-color1">{{ contactsLoyaltyPoints }}x</strong> Points on Contacts
                </div>
                <div class="templated-global-banner-loyalty-points">
                    <strong class="templated-global-popup-text-color1">{{ otherProductsLoyaltyPoints }}x</strong> Points on Other Purchases
                </div>
                <div class="small">
                    Join our <strong>FREE</strong> <a href="/rewards" class="templated-global-popup-text-color2">Rewards Program</a> to maximize your savings!
                </div>
            </div>
        </div>
        <div class="templated-global-banner-modal-exclusions templated-global-popup-text-color2">
            {{ exclusionText }}
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

import checkMobile from '@/utils/check-mobile';

export default {
    components: {
        Modal
    },
    props: {
        currentGlobalPopupKey: {
            type: String,
            default: ''
        },
        currentGlobalPopup: {
            type: Object,
            required: true
        },
        globalBannerModalOpen: {
            type: Boolean,
            default: false
        },
        currentGlobalBannerPopupComponentData: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.isMobile = checkMobile();
    },
    data() {
        return {
            isMobile: false
        }
    },
    computed: {
        percentOffText() {
            return this.currentGlobalBannerPopupComponentData?.percentOffText
                ? this.currentGlobalBannerPopupComponentData.percentOffText
                : [];
        },
        expirationDate() {
            return this.currentGlobalBannerPopupComponentData?.expirationDate;
        },
        headerText() {
            return this.currentGlobalBannerPopupComponentData?.headerText;
        },
        headerSubtext() {
            return this.currentGlobalBannerPopupComponentData?.headerSubtext;
        },
        emoji() {
            return this.currentGlobalBannerPopupComponentData?.emoji;
        },
        additionalContentClass() {
            return this.isMobile ? 'templated-global-banner-mobile-modal-content' : '';
        },
        percentOffDiy() {
            return this.currentGlobalBannerPopupComponentData?.percentOffDiy;
        },
        percentOffContacts() {
            return this.currentGlobalBannerPopupComponentData?.percentOffContacts;
        },
        percentOffLensReplacement() {
            return this.currentGlobalBannerPopupComponentData?.percentOffLensReplacement;
        },
        percentOffGlasses() {
            return this.currentGlobalBannerPopupComponentData?.percentOffGlasses;
        },
        percentOffSunglasses() {
            return this.currentGlobalBannerPopupComponentData?.percentOffSunglasses;
        },
        contactsLoyaltyPoints() {
            return this.currentGlobalBannerPopupComponentData?.contactsLoyaltyPoints;
        },
        otherProductsLoyaltyPoints() {
            return this.currentGlobalBannerPopupComponentData?.otherProductsLoyaltyPoints;
        },
        sameGlassesSunglassesPercentOff() {
            return this.percentOffGlasses === this.percentOffSunglasses;
        },
        promoCode() {
            return this.currentGlobalBannerPopupComponentData?.promoCode;
        },
        exclusionText() {
            return this.currentGlobalBannerPopupComponentData?.exclusionText
                ? this.currentGlobalBannerPopupComponentData.exclusionText
                : 'Excludes discounted frames, premium eyewear, accessories, online vision test; reward points apply.';
        },
    },
    methods: {
        toggleModalOpen(status) {
            this.$emit('close', status);
        }
    }
}
</script>

<style lang="scss">
@import '~/assets/scss/variables/breakpoints.scss';

.templated-global-banner-modal {
    .templated-global-banner-modal-header {
        display: flex;
        justify-content: space-between;
        border-bottom: solid rgba(255,255,255,.5) 1px;
        padding-bottom: 20px;
        .templated-global-banner-text-content {
            text-align: left;
            .templated-global-banner-header-text {
                font-size: 3.5em;
                font-weight: 900;
                line-height: 1.2em;
            }
            .templated-global-banner-header-subtext {
                font-size: 1.25em;
                line-height: 1em;
                font-weight: bold;
            }
        }
        .templated-global-banner-emoji-wrapper {
            font-size: 4em;
        }
    }
    .templated-global-banner-modal-content {
        display: flex;
        padding-top: 20px;
        text-align: left;
        .templated-global-banner-discounts {
            font-size: 1.25em;
            width: 50%;
            strong {
                display: inline-block;
                min-width: 90px;
                font-weight: 900;
            }
            .templated-global-banner-promo-code {
                display: flex;
                padding-top: 15px;
                font-size: 1.2em;
                .templated-global-popup-promo-code-bg-color1 {
                    padding: 6px;
                }
                .templated-global-popup-promo-code-bg-color2 {
                    padding: 6px;
                    font-weight: 900;
                }
            }
        }
        .templated-global-banner-loyalty {
            font-size: 1.25em;
            width: 50%;
            .small {
                padding-top: 20px;
                font-size: .8em;
                a {
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .templated-global-banner-modal-exclusions {
        text-align: left;
        padding-top: 20px;
        font-size: .75em;
    }
    .templated-global-banner-mobile-modal-content {
        position: fixed !important;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border: 0px;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        animation: animatebottom 0.4s;
    }
    @keyframes animatebottom {
        from {
            bottom: -300px;
            opacity: 0;
        }

        to {
            bottom: 0;
            opacity: 1;
        }
    }
}

@media (min-width: 1059px) {
    .templated-global-banner-modal {
        min-width: 891px;
    }
}

@media (max-width: $breakpoint2) {
    .templated-global-banner-modal {
        .modal-content {
            .title-section {
                display: none !important;
            }
            .modal-body {
                padding: 20px 32px;
            }
            .close-button {
                padding-top: 25px;
            }
        }
        .templated-global-banner-modal-header {
            .templated-global-banner-text-content {
                .templated-global-banner-header-text {
                    font-size: 1.75em;
                }
                .templated-global-banner-expires-date {
                    .templated-global-banner-emoji-wrapper {
                        font-size: 1.5em;
                        &.hidden-desktop {
                            display: inline-block !important;
                        }
                    }
                }
                .templated-global-banner-header-subtext {
                    font-size: 1em;
                }
            }
        }
        .templated-global-banner-modal-content {
            flex-direction: column-reverse;
            gap: 20px;
            .templated-global-banner-discounts {
                font-size: 1em;
                width: 100%;
                .templated-global-banner-promo-code {
                    font-size: 1.25em;
                }
                strong {
                    min-width: 75px;
                }
            }
            .templated-global-banner-loyalty {
                font-size: 1em;
                width: 100%;
                .small {
                    font-size: 1em;
                    padding-top: 12px;
                }
            }
        }
    }
}
</style>
