import productTypes from '@/constants/product-types';
import categoryTypes from '@/constants/category-types';
import objectFactory from '@/utils/object-factory';
import productSortOrderTypes from '@/constants/product-sort-order-types';

export const searchableProductTypes = [
    productTypes.CONTACT_LENSES.id,
    productTypes.SUNGLASSES.id,
    productTypes.GLASSES.id,
    productTypes.ACCESSORIES.id,
    productTypes.VISION_TEST.id,
    productTypes.LENS_REPLACEMENT.id,
    productTypes.DIY_LENSES.id,
    productTypes.GIFT_CARD.id
];

export const getViewAllEndpoints = (searchResults) => {
    let counts = {};
    for (let x = 0; x < searchResults.length; x++) {
        let product = searchResults[x];
        let productType = product.productType;
        if (searchableProductTypes.indexOf(productType) === -1) {
            continue;
        }

        if (typeof counts[productType] === 'undefined') {
            counts[productType] = 0;
        }

        counts[productType]++;
    }

    let maxProductType = null;
    let maxCount = null;
    for (let productType in counts) {
        if (maxProductType === null) {
            maxProductType = parseInt(productType);
            maxCount = counts[productType];
            continue;
        }

        if (maxCount < counts[productType]) {
            maxProductType = parseInt(productType);
            maxCount = counts[productType];
        }
    }

    switch (maxProductType) {
        case productTypes.SUNGLASSES.id:
            return '/sunglasses';
        case productTypes.GLASSES.id:
            return '/glasses';
        case productTypes.ACCESSORIES.id:
            return '/accessories';
        case productTypes.VISION_TEST.id:
            return '/vision-test';
        case productTypes.LENS_REPLACEMENT.id:
            return '/choose-your-lenses';
        case productTypes.DIY_LENSES.id:
            return '/diy-lens-replacement';
        case productTypes.GIFT_CARD.id:
            return '/giftcards';
    }

    return '/contact-lenses';
};

export const formatSearchQuery = (payload) => {
    let query = {};

    if (payload.categoryFilters && payload.categoryFilters.length > 0) {
        for (let x = 0; x < payload.categoryFilters.length; x++) {
            query['categoryFilterOption[' + x + ']'] = payload.categoryFilters[x];
        }
    }

    if (payload.sortBy) {
        query.sortBy = payload.sortBy;
    }

    if (payload.productName) {
        query.productName = payload.productName;
    }

    return query;
};

export const formatResultsForGoogleTracking = (results) => {
    let formattedResults = [];
    for (let hit of results.hits) {
        formattedResults.push({ id: hit.objectID });
    }

    return formattedResults;
}

export const getCategoryTypeAttributeMappings = () => {
    let mappings = {};

    mappings[categoryTypes.FIT.id] = 'facetFit';
    mappings[categoryTypes.MATERIAL.id] = 'facetMaterial';
    mappings[categoryTypes.FRAME_SHAPE.id] = 'facetFrameShape';
    mappings[categoryTypes.COLOR.id] = 'facetColor';
    mappings[categoryTypes.GENDER.id] = 'facetGender';
    mappings[categoryTypes.RIM.id] = 'facetRim';
    mappings[categoryTypes.FACE_SHAPE.id] = 'facetFaceShape';
    mappings[categoryTypes.LENS_TYPE.id] = 'facetLensType';
    mappings[categoryTypes.BRAND.id] = 'facetBrand';
    mappings[categoryTypes.BRAND_STYLE.id] = 'facetBrandStyle';
    mappings[categoryTypes.MANUFACTURER.id] = 'facetManufacturer';
    mappings[categoryTypes.TREND.id] = 'facetTrend';

    return mappings;
};



export const processAlgoliaResults = (results, existingProducts) => {
    let products = objectFactory.deepCopy(existingProducts);
    for (let hit of results.hits) {
        let parentElement = getParentElement(hit, products);
        if (parentElement && parentElement.variations.length > 5) {
            continue;
        }

        if (parentElement) {
            parentElement.variations.push(hit);
            continue;
        }

        hit.variations = [];

        products.push(hit);
    }

    return products;
};

export const getParentElement = (item, products) => {
    for (let product of products) {
        if (item.parentId === product.parentId) {
            return product;
        }
    }

    return null;
};

export const getAlgoliaAttributeForCategoryType = (categoryType) => {
    if (!categoryType) {
        return null;
    }

    const algoliaAttributeMappings = getCategoryTypeAttributeMappings();

    if (typeof algoliaAttributeMappings[categoryType] === 'undefined') {
        return null;
    }

    return algoliaAttributeMappings[categoryType];
};

export const getRefinementListDataByFilterId = (categoryFilterId, categoryFilters) => {
    for (let categoryFilter of categoryFilters) {
        let categoryType = categoryFilter.categoryType;
        for (let categoryFilterOption of categoryFilter.categoryFilterOptions) {
            if (categoryFilterOption.id !== categoryFilterId) {
                continue;
            }

            let algoliaAttribute = getAlgoliaAttributeForCategoryType(categoryType);
            if (!algoliaAttribute) {
                continue;
            }

            return {
                algoliaAttribute: algoliaAttribute,
                name: categoryFilterOption.name
            }
        }
    }

    return null;
};

export const getSortByIndexById = (id, algoliaConfig) => {
    switch (id) {
        case productSortOrderTypes.RELEVANCE.id:
            return algoliaConfig.sort.relevance;
        case productSortOrderTypes.POPULARITY.id:
            return algoliaConfig.sort.popularity;
        case productSortOrderTypes.PRICE_LOW_TO_HIGH.id:
            return algoliaConfig.sort.priceLowToHigh;
        case productSortOrderTypes.PRICE_HIGH_TO_LOW.id:
            return algoliaConfig.sort.priceHighToLow;
        case productSortOrderTypes.ALPHABETICAL.id:
            return algoliaConfig.sort.alphabetical;
        case productSortOrderTypes.ALPHABETICAL_REVERSE.id:
            return algoliaConfig.sort.alphabeticalReverse;
    }

    return algoliaConfig.sort.popularity;
};

export const getCategoryFilterIdsFromQueryObject = (queryParams) => {
    let counter = 0;
    let categoryField = 'category[' + counter + ']';
    let categoryFilterIds = [];

    while (typeof queryParams[categoryField] !== 'undefined') {
        categoryFilterIds.push(
            isNaN(queryParams[categoryField])
                ? queryParams[categoryField]
                : parseInt(queryParams[categoryField])
        );
        counter++;
        categoryField = 'category[' + counter + ']';

        if (counter > 100) {
            break;
        }
    }

    return categoryFilterIds;
};

export const getFiltersForSearch = (queryParams, categoryFilters) => {
    let categoryFilterIds = getCategoryFilterIdsFromQueryObject(queryParams);
    let indexMapping = {};
    let counter = 0;
    let groupedFilters = [];
    for (let categoryFilterId of categoryFilterIds) {
        let refinementData = getRefinementListDataByFilterId(categoryFilterId, categoryFilters);
        if (!refinementData?.algoliaAttribute) {
            continue;
        }

        let index = typeof indexMapping[refinementData.algoliaAttribute] === 'undefined'
            ? null
            : indexMapping[refinementData.algoliaAttribute];

        if (index === null) {
            indexMapping[refinementData.algoliaAttribute] = counter;
            groupedFilters[counter] = [];
            index = counter;
            counter++;
        }

        groupedFilters[index].push(categoryFilterId);
    }

    return groupedFilters;
};

export const getFiltersByCategoryIds = (categoryQueryParam, categoryFilters) => {
    const categoryIds = Array.isArray(categoryQueryParam) ? categoryQueryParam : [categoryQueryParam];
    const filterOptions = [];

    for (let categoryId of categoryIds) {
        if (isNaN(categoryId)) {
            continue;
        }

        for (let categoryFilter of categoryFilters) {
            for (let categoryFilterOption of categoryFilter.categoryFilterOptions) {
                if (categoryFilterOption?.categoryData?.id !== parseInt(categoryId)) {
                    continue;
                }

                let filterOption = objectFactory.deepCopy(categoryFilterOption);
                const algoliaAttribute = getAlgoliaAttributeForCategoryType(
                    categoryFilter.categoryType
                );

                filterOption.algoliaFacetAttribute = algoliaAttribute;

                filterOptions.push(filterOption);
            }
        }
    }

    return filterOptions;
};

export const getFilterById = (filterId, categoryFilters) => {
    for (let categoryFilter of categoryFilters) {
        for (let categoryFilterOption of categoryFilter.categoryFilterOptions) {
            if (categoryFilterOption.id !== filterId) {
                continue;
            }

            let filterOption = objectFactory.deepCopy(categoryFilterOption);
            const algoliaAttribute = getAlgoliaAttributeForCategoryType(
                categoryFilter.categoryType
            );

            filterOption.algoliaFacetAttribute = algoliaAttribute;

            return filterOption;
        }
    }

    return null;
};

export const formatAlgoliaResults = (results) => {
    let products = [];
    for (let hit of results.hits) {
        let parentElement = getParentElement(hit, products);
        if (parentElement && parentElement.variations.length > 5) {
            continue;
        }

        if (parentElement) {
            parentElement.variations.push(hit);
            continue;
        }

        hit.variations = [];

        products.push(hit);
    }

    return products;
};
