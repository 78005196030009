<template>
    <div class="templated-global-banner-component">
        <div class="hidden-mobile custom-global-banner-text">
            <div v-if="banner.emoji" class="emoji">{{ banner.emoji }}</div>
            <div>{{ banner.desktopBannerText }}</div>
            <div v-if="banner.promoCode" :style="promoCodeStyle">
                {{ banner.promoCode }}
            </div>
            <div v-if="banner.desktopCtaText">
                |
            </div>
            <div
                v-if="banner.desktopCtaText"
                class="hover-underline"
            >
                {{ banner.desktopCtaText }}
            </div>
            <div v-if="banner.expirationDate">
                |
            </div>
            <div
                v-if="banner.expirationDate"
            >
                Ends {{ banner.expirationDate }}
            </div>
        </div>
        <div class="hidden-desktop custom-global-banner-text">
            <div v-if="banner.emoji" class="emoji">{{ banner.emoji }}</div>
            <div>{{ banner.mobileBannerText }}</div>
            <div v-if="banner.promoCode">
                |
            </div>
            <div v-if="banner.promoCode" :style="promoCodeStyle">
                {{ banner.promoCode }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        banner: {
            type: Object,
            required: true
        }
    },
    computed: {
        promoCodeStyle() {
            const promoCodeStyle = {
                fontWeight: 'bold'
            };

            if (this.banner.promoCodeColor) {
                promoCodeStyle.color = this.banner.promoCodeColor;
            }

            return promoCodeStyle;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.templated-global-banner-component {
    .custom-global-banner-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        &.hidden-desktop {
            display: none;
        }
    }
}

@media (max-width: $breakpoint2) {
    .templated-global-banner-component {
        .custom-global-banner-text {
            &.hidden-mobile {
                display: none;
            }
            &.hidden-desktop {
                display: flex !important;
            }
        }
    }
}
</style>
