import objectFactory from '@/utils/object-factory';

const SET_MENU_ITEM = 'Description';
const SET_FROM_CATEGORY = 'Set from category'

export const state = () => ({
    descriptionMenuItem: "Description",
    fromCategory: {},
});

export const mutations = {
    [SET_MENU_ITEM](state, payload) {
        state.descriptionMenuItem = payload;
    },
    [SET_FROM_CATEGORY](state, { productId, category }) {
        const fromCategory = {};
        fromCategory[productId] = category;

        state.fromCategory = fromCategory;
    }
};

export const actions = {
    setDescriptionMenuItem({ commit }, payload) {
        commit(SET_MENU_ITEM, payload);
        return Promise.resolve();
    },
    setFromCategory({ commit }, { productId, category }) {
        commit(SET_FROM_CATEGORY, { productId, category });
        return Promise.resolve();
    }
};

export const getters = {
    descriptionMenuItem(state) {
        return state.descriptionMenuItem
    }
};
