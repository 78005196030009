<template>
    <div class="mobile-nav-category-wrapper">
        <button class="mobile-nav-category-button" @click="onClickCategoryButton">
            <slot></slot>
            <img class="expand-icon" :src="expandIcon" v-if="links.length > 0" alt="arrow icon">
        </button>
        <TransitionExpand>
            <div v-if="links.length > 0 && expand">
                <div class="menu-list-container">
                    <div v-for="(link, index) in links" :key="index">
                        <a :href="link.url" @click.prevent="onClickLink(link.url)">
                            {{ link.text }}
                            <span v-if="link.isNew" class="badge-red">NEW</span>
                        </a>
                    </div>
                </div>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand';
import imageUrlFormatterMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [imageUrlFormatterMixin],
    components: {
        TransitionExpand
    },
    props: {
        links: {
            type: Array,
            default: () => ([])
        },
        to: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            expand: false
        };
    },
    computed: {
        expandIcon() {
            return this.formatAssetUrl(this.expand ? 'images/angle-up-blue.svg' : 'images/angle-down-blue.svg');
        }
    },
    methods: {
        onClickLink(to) {
            this.$router.push({ path: to });
            this.$emit('clickedLink');
        },
        onClickCategoryButton() {
            if (this.to) {
                this.onClickLink();
                this.$router.push({ path: this.to });
                return;
            }

            this.expand = !this.expand;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';

    .mobile-nav-category-wrapper {
        padding: 10px 5px;
        border-bottom: solid $color-grey-border 1px;
        background-color: #ffffff;
        .mobile-nav-category-button {
            display: flex;
            align-items: center;
            font-weight: bold;
            background-color: #ffffff;
            padding: 10px;
            border: 0px;
            width: 100%;
            font-size: 20px;
            color: #000000;
            .expand-icon {
                margin-left: auto;
            }
        }

        .menu-list-container {
            text-align: left;
            padding: 10px;
            a {
                display: inline-block;
                color: #000000;
                font-size: 18px;
                padding-bottom: 10px;
                .badge-red {
                    display: inline-block;
                    margin-left: 5px;
                    color: #ffffff;
                    background-color: #c70b15;
                    font-weight: bold;
                    border-radius: 3px;
                    padding: 7px 7px;
                    font-size: .75em;
                    line-height: .75em;
                }
            }
        }
    }
</style>
