<template>
    <b-modal
        :visible="value"
        @hidden="onClickClose"
        hide-footer
        hide-header
        :centered="centered"
        :dialog-class="dialogClass"
        :no-close-on-backdrop="disableBackgroundClickClose"
        :content-class="additionalContentClass"
        :no-fade="noFade"
    >
        <CloseButton class="close-button" @click="onClickClose" />
        <div class="title-section"><slot name="title"></slot></div>
        <slot> </slot>
    </b-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        additionalDialogClass: {
            type: String,
            default: ''
        },
        disableBackgroundClickClose: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: true
        },
        additionalContentClass: {
            type: String,
            default: ''
        },
        noFade: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialogClass() {
            return this.additionalDialogClass + ' modal-container';
        }
    },
    methods: {
        onClickClose() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';
::v-deep {
    .modal-container {
        .modal-content {
            border-radius: 10px;
            .title-section {
                margin: -15px 0 30px -15px;
            }
        }

        .close-button {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 24px;
            padding: 0px;
            margin: 0px;
            color: #000000;
            &:hover {
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }
}
@media (max-width: $breakpoint5) {
}
</style>
