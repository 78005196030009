<template>
    <Modal
        :value="globalBannerModalOpen"
        :additionalDialogClass="currentGlobalPopup.classNamePrefix + '_' + currentGlobalPopupKey + ' custom-global-banner-sale-modal'"
        @close="toggleModalOpen(false)"
    >
        <div class="custom-global-popup-text" v-html="currentGlobalPopupContent"></div>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

import { parseContentText } from '@/utils/ui-components';

export default {
    components: {
        Modal
    },
    props: {
        currentGlobalPopupKey: {
            type: String,
            default: ''
        },
        currentGlobalPopup: {
            type: Object,
            required: true
        },
        globalBannerModalOpen: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentGlobalPopupContent() {
            try {
                let content = this.currentGlobalPopup?.componentData[0].content
                    ? this.currentGlobalPopup?.componentData[0].content
                    : '';

                return parseContentText(content, this.currentGlobalPopup?.componentData[0].highlightColor);
            } catch (e) {
                return '';
            }
        }
    },
    methods: {
        toggleModalOpen(status) {
            this.$emit('close', status);
        }
    }
}
</script>
